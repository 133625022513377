import { Slide, Tooltip, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import usePublishedDomains from "../../Hooks/usePublishedDomains";
import "./NavMenu.scss";
import ProudLink from "./ProudLink/ProudLink";

export default function NavMenu() {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const toggleMenu = () => {
    setOpen((prev) => !prev);
  };

  const domains = usePublishedDomains();

  useEffect(() => {
    if (open) {
      document.querySelector("body").classList.add("stop-scrolling");
    } else {
      document.querySelector("body").classList.remove("stop-scrolling");
    }
  }, [open]);

  return (
    <nav className="nav-menu">
      <Tooltip title={open ? 'Fermer le menu' : 'Ouvrir le menu'}>
        <div className={`menu ${open && "open"}`} onClick={toggleMenu}>
          <div
            className="menu-bar bar-1"
            style={{
              backgroundColor: open
                ? theme.palette.primary.main
                : theme.palette.text.primary,
            }}
          />
          <div
            className="menu-bar bar-2"
            style={{
              backgroundColor: theme.palette.primary.main,
            }}
          />
          <div
            className="menu-bar bar-3"
            style={{
              backgroundColor: open
                ? theme.palette.primary.main
                : theme.palette.text.primary,
            }}
          />
        </div>
      </Tooltip>

      <Slide in={open} direction="right">
        <div
          className="fullscreen-menu"
          style={{
            background: "rgba(0,0,10,0.92)",
          }}
          onClick={() => {
            setOpen(false);
          }}
        >
          <ul>
            <ProudLink path="/" label="Accueil" />
            <ProudLink path="/about" label="Proud about ?" />
            {domains?.map((domain) => {
              return (
                <ProudLink path={domain.data.path} label={domain.data.title} key={domain.data.path}/>
              );
            })}
            <ProudLink path="/contact" label="Contact" />
          </ul>
        </div>
      </Slide>
    </nav>
  );
}
