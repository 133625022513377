import {
  Button,
  ButtonGroup,
  Card,
  Collapse,
  Container,
  FormControlLabel,
  IconButton,
  Switch,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import SettingsBackupRestoreIcon from "@mui/icons-material/SettingsBackupRestore";
import _, { orderBy as _orderBy } from "lodash";
import React, { useEffect, useState } from "react";
import DeleteProject from "../DeleteProject/DeleteProject";
import SmartGallery from "../SmartGallery/SmartGallery";
import "./ContentDomainProjectCard.scss";
import { doc, updateDoc, collection, query, orderBy, getDocs } from "@firebase/firestore";
import { useSnackbar } from "notistack";
import { db } from "../../database/firebase";

export default function ContentDomainProjectCard({
  initialProject,
  projectId,
  domainTitle,
  domainId,
}) {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();

  const [open, setOpen] = useState(false);
  const handleClick = () => {
    setOpen((prev) => !prev);
  };

  //DATA HANDLING
  const [project, setProject] = useState(initialProject);
  const [edited, setEdited] = useState(false);

  useEffect(() => {
    setProject(initialProject)
  }, [initialProject])

  useEffect(() => {
    setEdited(!_.isEqual(project, initialProject));
  }, [project, initialProject]);


  const reorderProjects = async (newProjectOrder) => {
    try {
      const collectionRef = collection(db, "content", domainId, "projects");
      const queryRef = query(collectionRef, orderBy("order"));
      const snapshot = await getDocs(queryRef);
  
      let projects = [];
      snapshot.forEach((doc) => {
        projects.push({ id: doc.id, ...doc.data() });
      });
  
      // Réordonner les projets
      projects.forEach((project) => {
        if (project.id === projectId) {
          // Mettre à jour l'ordre du projet modifié
          project.order = newProjectOrder;
        } else {
          if (newProjectOrder < initialProject.order) {
            // Si le projet est déplacé vers l'avant
            if (project.order >= newProjectOrder && project.order < initialProject.order) {
              project.order++;
            }
          } else {
            // Si le projet est déplacé vers l'arrière
            if (project.order <= newProjectOrder && project.order > initialProject.order) {
              project.order--;
            }
          }
        }
      });
  
      // Trier les projets par ordre
      projects = _orderBy(projects, ["order"], ["asc"]);
  
      // Sauvegarder les changements
      for (const project of projects) {
        const docRef = doc(db, "content", domainId, "projects", project.id);
        await updateDoc(docRef, { order: project.order });
      }
    } catch (error) {
      console.error("Erreur lors de la réorganisation des projets :", error);
      // Gérer l'erreur (par exemple, en affichant un message à l'utilisateur)
      enqueueSnackbar(`Erreur lors de la réorganisation des projets : ${error.message}`, { variant: "error" });
    }
  };
  

  //SAVE CHANGES
  const saveProject = () => {
    let docRef = doc(db, "content", domainId, "projects", projectId);
    updateDoc(docRef, project)
      .catch(
        (e) =>
          enqueueSnackbar(
            `Impossible de sauvegarder les changements : ${e.message}`
          ),
        { variant: "error" }
      )
      .then(() => {
        enqueueSnackbar("Changements sauvegardés", { variant: "success" });
        setEdited(false);
        if (initialProject.order !== project.order) {
          console.log("Reorder");
          reorderProjects(project.order);
        }
      });
  };

  //RESET CHANGES
  const resetDatas = () => {
    setProject(initialProject);
  };

  //Input handlers
  const handleOrderChange = (e) => {
    setProject((prev) => ({
      ...prev,
      order: parseInt(e.target.value),
    }));
  };

  const handlePublishedChange = (e) => {
    setProject((prev) => ({
      ...prev,
      published: e.target.checked,
    }));
  };

  const handleNameChange = (e) => {
    setProject((prev) => ({
      ...prev,
      name: e.target.value,
    }));
  };
  
  const handleSubtitleChange = (e) => {
    setProject((prev) => ({
      ...prev,
      subtitle: e.target.value,
    }));
  };
  const handleNumberOfParticipantsChange = (e) => {
    setProject((prev) => ({
      ...prev,
      numberOfParticipants: e.target.value,
    }));
  };
  const handleNumberOfEventsChange = (e) => {
    setProject((prev) => ({
      ...prev,
      numberOfEvents: e.target.value,
    }));
  };

  const handleDateChange = (e) => {
    setProject((prev) => ({
      ...prev,
      date: e.target.value,
    }));
  };

  const handleMissionsChange = (e) => {
    setProject((prev) => ({
      ...prev,
      missions: e.target.value,
    }));
  };

  const handleShortDescChange = (e) => {
    setProject((prev) => ({
      ...prev,
      short_description: e.target.value,
    }));
  };
  const handleDescChange = (e) => {
    setProject((prev) => ({
      ...prev,
      description: e.target.value,
    }));
  };

  return (
    <>
      <Card
        className="domain-project"
        elevation={2}
        sx={{
          width: "100%",

          display: "flex",
          flexDirection: "column",
          alignItems: "center",

          cursor: open ? "auto" : "pointer",
        }}
      >
        <div className="domain-project-head" onClick={handleClick}>
          <Typography variant="h6" p={1}>
            {project.name}
          </Typography>
          {project.gallery[0] && (
            <img
              className="project-thumbnail"
              src={project.gallery[0]?.thumbnail}
              alt={`Représentation du projet ${project.name}`}
            />
          )}
           <Typography fontSize="2em" color="GrayText" className="project-order">
            {project.order}
          </Typography>
        </div>
        <Collapse in={open} sx={{ width: "100%" }}>
          <div
            className="domain-project-body"
            style={{ gap: theme.spacing(2) }}
          >
            <FormControlLabel
              control={
                <Switch
                  checked={project.published ? true : false}
                  onChange={handlePublishedChange}
                />
              }
              label="En ligne"
            />
            <TextField
              id="order"
              label="Ordre"
              type="number"
              value={project.order}
              onChange={handleOrderChange}
            />
            <TextField
              id="project-name-input"
              label="Nom"
              value={project.name}
              onChange={handleNameChange}
            />
            <TextField
              id="project-subtitle-input"
              label="Sous-titre"
              value={project.subtitle}
              onChange={handleSubtitleChange}
            />
            <TextField
              id="project-nbOfParticipants-input"
              label="Nombre de participants"
              value={project.numberOfParticipants}
           
              onChange={handleNumberOfParticipantsChange}
            />
            <TextField
              id="project-nbOfEvents-input"
              label="Nombre d'évènements"
              value={project.numberOfEvents}
            
              onChange={handleNumberOfEventsChange}
            />
            <TextField
              id="project-date-input"
              label="Date"
              value={project.date}
              type="month"
              onChange={handleDateChange}
            />
            <TextField
              id="project-missions-input"
              label="Missions, objectifs, etc. (séparés par des virgules)"
              value={project.missions}
              onChange={handleMissionsChange}
            />
            <TextField
              id="project-short-description-input"
              multiline
              label="Description courte"
              value={project.short_description}
              onChange={handleShortDescChange}
            />
            <TextField
              id="project-description-input"
              multiline
              label="Description longue"
              value={project.description}
              onChange={handleDescChange}
            />
            <SmartGallery
              projectName={initialProject.name}
              projectGallery={initialProject.gallery}
              domainTitle={domainTitle}
              projectId={projectId}
              domainId={domainId}
            />
            <Container align="center" sx={{ p: theme.spacing(2) }}>
              <ButtonGroup variant="contained">
                <Button onClick={saveProject} disabled={!edited}>
                  Enregistrer
                </Button>
                <Tooltip
                  title={
                    edited ? "Annuler les changements" : "Aucun changement"
                  }
                >
                  <span>
                    <IconButton disabled={!edited} onClick={resetDatas}>
                      <SettingsBackupRestoreIcon
                        color={edited ? "primary" : ""}
                      />
                    </IconButton>
                  </span>
                </Tooltip>
                <DeleteProject
                  projectId={projectId}
                  domainId={domainId}
                  domainTitle={domainTitle}
                  projectName={initialProject.name}
                />
              </ButtonGroup>
            </Container>
            <Button onClick={handleClick}>Fermer</Button>
          </div>
        </Collapse>
      </Card>
    </>
  );
}
