import { Container, Icon, Typography, useTheme } from "@mui/material";
import React, { useEffect } from "react";
import Emphasis from "../../Components/Emphasis";
import Subscribe from "../../Components/Subscribe/Subscribe";
import "./Contact.scss";
import { set } from "lodash";
import bg from './concert.png'
import { Instagram, LinkedIn } from "@mui/icons-material";
import { Link } from "react-router-dom";

export default function Contact(props) {
  useEffect(() => {
    let oldTitle = document.title;
    document.title = props.title;
    props.setAppBG([bg]);
    return () => {
      document.title = oldTitle;
      props.setAppBG([]);
    };
  }, []);

  const theme = useTheme();

  return (
    <>
      <Container sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: "space-between",
        flex: '1',
      }}>
        <Container>
          <Container>
            <Typography color="text.primary" variant="h2" mt={2} mb={2}>
              Contact
            </Typography>

            <Typography color="text.primary" as="p" mt={1} mb={2} maxWidth={700}>
              Une approche résolument <strong>atypique</strong> de la production événementielle,
              centrée sur la <strong>confiance</strong>, le <strong>dynamisme</strong> et la <strong>rigueur</strong>.
            </Typography>
            <Typography color="text.primary" as="p" mt={1} mb={2} maxWidth={700}>
              La volonté d’être toujours <strong>agiles</strong>, <strong>pragmatiques</strong> et <strong>flexibles</strong>.
            </Typography>
            <Typography color="text.primary" as="p" mt={1} mb={2} maxWidth={700}>
              L’assurance d’avoir le <strong>même interlocuteur</strong> tout au long de votre projet.
            </Typography>
            <div className="social_links_container">
              <Typography color="text.primary" as="p" mt={9} mb={2} maxWidth={700}>
                <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/company/proud-projects/">
                  <LinkedIn fontSize='large' />
                </a>
                <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/proudprojectsparis/">
                  <Instagram fontSize="large" />
                </a>
              </Typography>
            </div>


            <Typography color="text.primary" variant="h5" as="p" >
              <a href="mailto:hello@proud-projects.com">
                <Emphasis color={theme.palette.text.primary}>{props.email || ''}</Emphasis>
              </a>
            </Typography>
          </Container>
        </Container>

      </Container>
    </>
  );
}
