import {
  collection,
  query,
  onSnapshot,
  orderBy,
} from "firebase/firestore";
import { useState, useEffect } from "react";
import { db } from "../database/firebase";

function usePublishedDomains() {
  const [domains, setDomains] = useState(null);

  useEffect(() => {
    const q = query(
      collection(db, "content"),
      orderBy("order", "asc")
    );
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const appDomains = [];
      querySnapshot.forEach((doc) => {
        if (doc.data().published) {
          appDomains.push({
            id: doc.id,
            data: doc.data(),
          });
        }
      });
      setDomains(appDomains);
    });

    return () => unsubscribe();
  }, []);

  return domains;
}

export default usePublishedDomains;
