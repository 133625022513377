import { Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import "./Footer.css";

export default function Footer(props) {

  const year = new Date().getFullYear();

  return (
    <footer>
      <Typography color="text.primary" align="center" fontSize="0.75em" >
        Proud Projects © {year} - <Link to="/legal">Mentions légales</Link>{" "}
        {props.user ? (
          <>
            - Connecté en tant que {props.user.email} -{" "}
            <Link to="/ppa">Panneau d'administration</Link>
          </>
        ) : (
          ""
        )}
      </Typography>
    </footer>
  );
}
