import { arrayUnion, doc, updateDoc } from "@firebase/firestore";
import { getDownloadURL, ref, uploadBytes } from "@firebase/storage";
import {
  Button,
  ButtonGroup,
  IconButton,
  Paper,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import { db, storage } from "../../database/firebase";
import { v4 as uuidv4 } from "uuid";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import FirebaseImage from "../FirebaseImage/FirebaseImage";
import "./FirebaseGallery.scss";
import useGallery from "../../Hooks/useGallery";

export default function FirebaseGallery({
  docId,
}) {
  const theme = useTheme();

  const gallery = useGallery(docId)

  const [galleryInput, setGalleryInput] = useState([]);
  const [galleryBlobs, setGalleryBlobs] = useState([]);

  const handleGalleryChange = (event) => {
    let files = Array.from(event.target.files);
    files.forEach((galleryImage) => {
      setGalleryInput((prev) => [...prev, galleryImage]);
      let url = URL.createObjectURL(galleryImage);
      setGalleryBlobs((prev) => [...prev, url]);
    });
  };

  const handleSubmit = () => {
    galleryInput.forEach((img) => {
      let imageUuid = img.name + uuidv4();
      let imageRef = ref(
        storage,
        `images/galleries/${docId}/${imageUuid}`
      );

      uploadBytes(imageRef, img).then(() => {
        getDownloadURL(imageRef).then((url) => {
          let assetArray = {
            id: imageUuid,
            original: url,
            thumbnail: url,
            originalTitle: img.name,
          };
          console.log(assetArray);
          let docRef = doc(db, "galleries", docId);
          updateDoc(docRef, {
            images: arrayUnion(assetArray),
            test: "tesete"
          }).then(() => {
            console.log("Document successfully updated!");
          }
          ).catch((error) => {
            console.error("Error updating document: ", error);
          }
          );
        });
      });
    });
    resetInput();
  };
  const resetInput = () => {
    setGalleryInput([]);
    setGalleryBlobs([]);
  };

  return (
    <>
      <Typography align="center" color="text.primary">Gallerie</Typography>
      <div className="gallery" style={{ gap: theme.spacing(1) }}>
        {gallery &&
          gallery.map((asset, index) => {
            return (
              <Paper
                index={index}
                key={index}
                sx={{ p: theme.spacing(1), height: "150px", width: "100px" }}
                // onDragStart={(e) => handleDragStart(e, index)}
                // onDrag={(e) => handleDrag(e, index)}
                // onDragEnd={(e) => handleDragEnd(e, index)}
                // draggable
                className="gallery-item"
              >
                <FirebaseImage
                  asset={asset}
                  docId={docId}
                />
              </Paper>
            );
          })}
        <Tooltip title="Ajouter une/des photo(s) à la gallerie">
          <IconButton
            component="label"
            sx={{
              p: theme.spacing(1),
              height: "150px",
              width: "100px",
              cursor: "pointer",
              borderRadius: '4px',
              boxSizing: 'content-box'
            }}
          >
            <AddPhotoAlternateIcon fontSize="large" />
            <input
              type="file"
              name="project-gallery"
              id="project-gallery"
              hidden
              multiple
              accept="image/png, image/gif, image/jpeg"
              onChange={handleGalleryChange}
            />
          </IconButton>
        </Tooltip>
      </div>
      {galleryInput[0] && (
        <div className="gallery-preview">
          <Typography align="center" variant="caption">
            Prévisualisation de la selection:
          </Typography>
          <div className="previews-container">
            {galleryBlobs.map((src, index) => {
              return (
                <img
                  key={index}
                  src={src}
                  alt="Prévisualisation de la selection"
                  style={{
                    border: `solid 1px ${theme.palette.primary.main}`,
                    borderRadius: theme.shape.borderRadius,
                  }}
                />
              );
            })}
          </div>
          <ButtonGroup>
            <Button onClick={resetInput} color="error">
              Annuler
            </Button>
            <Button onClick={handleSubmit} variant="contained" color="success">
              Valider la sélection
            </Button>
          </ButtonGroup>
        </div>
      )}
    </>
  );
}
