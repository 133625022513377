import { signInWithEmailAndPassword } from "@firebase/auth";
import { Button, InputAdornment, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import "./LogIn.scss";
import { auth } from "../../database/firebase";

import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

import { useSnackbar } from "notistack";

export default function LogIn(props) {
  useEffect(() => {
    document.title = props.title;
  });

  const { enqueueSnackbar } = useSnackbar();

  const [user, setUser] = useState({});
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [passwordVisibility, setPasswordVisibility] = useState(false);

  const signIn = () => {
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        setUser(userCredential);
        setEmail("");
        setPassword("");
        enqueueSnackbar(`Vous êtes maintenant connecté`, {
          variant: "success",
        });
      })
      .catch((error) => {
        let message = "";
        switch (error.code) {
          case "auth/wrong-password":
            message = "le mot de passe saisi est incorrect";
            break;

          case "auth/user-not-found":
            message = "l'addresse mail saisie est inconnue";
            break;

          case "auth/invalid-email":
            message = "le format de l'addresse mail est incorrect";
            break;

          default:
            break;
        }
        enqueueSnackbar(`Erreur de connexion : ${message}`, {
          variant: "error",
        });
        console.log(error.code, error.message);
      });
  };

  const handlePasswordVisibility = () => {
    setPasswordVisibility((prev) => !prev);
  };

  return (
    <div className="logIn">
      <form action="">
        <TextField
          id="username"
          label="Adresse e-mail"
          sx={{ width: "230px" }}
          type="username"
          autoComplete="username"
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              signIn();
            }
          }}
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
        />
        <TextField
          id="password"
          label="Mot de passe"
          sx={{ width: "230px" }}
          type={passwordVisibility ? "text" : "password"}
          autoComplete="current-password"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {passwordVisibility ? (
                  <VisibilityOffIcon
                    className="visibility"
                    onClick={handlePasswordVisibility}
                  />
                ) : (
                  <VisibilityIcon
                    className="visibility"
                    onClick={handlePasswordVisibility}
                  />
                )}
              </InputAdornment>
            ),
          }}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              signIn();
            }
          }}
          value={password}
          onChange={(e) => {
            setPassword(e.target.value);
          }}
        />
        <Button onClick={signIn} variant="contained">
          Connexion
        </Button>
      </form>
    </div>
  );
}
