import { Container, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import FormatedText from "../../Components/FormatedText";
import ProjectCard from "../../Components/ProjectCard/ProjectCard";
import SelectedProject from "../../Components/SelectedProject/SelectedProject";
import usePublishedProjects from "../../Hooks/usePublishedProjects";
import "./DomainPage.scss";

export default function DomainPage({ domain, setAppBG }) {

  useEffect(() => {
    let oldTitle = document.title
    document.title = oldTitle + " - " + domain.data.title
    return () => {
      document.title=oldTitle
    }
  }, [domain])

  const theme = useTheme();

  const projects = usePublishedProjects(domain.id);

  //State : Project selected
  const [selected, setSelected] = useState(null);

  //Set selected to false when ESC is pressed
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === "Escape") {
        setSelected(null);
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);


  //Dialog stuff
  const handleClose = () => {
    setSelected(null);
  };

  // Setting App's background when location changes
  useEffect(() => {
    setAppBG((prev) => [...prev, domain.data.image]);
    setTimeout(() => {
      setAppBG([domain.data.image]);
    }, 1000);
    return () => {
      setAppBG([]);
    };
  }, [window.location.pathname]);

  return (
    <>
      <Container>
        <Container
          className="domain-container"
          sx={{
            display: "flex",
            gap: `${theme.spacing(5)} ${theme.spacing(10)}`,
            mb: theme.spacing(3),
            [theme.breakpoints.down('md')]: {
              justifyContent: 'center',
            }
          }}
        >
          <Container className="domain-infos">
            <FormatedText text={domain.data.title || ''} variant="h2" as="h2" fontWeight={300} mt={2} mb={2}/>
            <FormatedText text={domain.data.subtitle || ''} variant="h3" as="h3" fontWeight={300}  mb={2}/>
            <FormatedText text={domain.data.long_desc || ''} />
          </Container>
          {projects &&
            projects.map((project, i) => {
              return (
                <ProjectCard
                  key={project.data.name}
                  project={project}
                  index={i}
                  selected={selected}
                  setSelected={setSelected}
                />
              );
            })}
        </Container>
      </Container>

      <div style={{
        height: '100dvh',
        width: '100vw',
        position: 'fixed',
        top: '0px',
        left: '0px',
        zIndex: 67,
        backdropFilter: selected ? 'blur(5px)' : 'blur(0px)',
        pointerEvents: selected ? 'all' : 'none',
        transition: 'all 0.2s ease-in-out',
      }}>

      </div>
      <SelectedProject project={selected} handleClose={handleClose}/>
      
    </>
  );
}
