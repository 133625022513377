import { Card, Chip, Tooltip } from "@mui/material";
import React, { useState } from "react";
import _ from "lodash";
import "./ProjectCard.scss";
import FormatedText from "../FormatedText";
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import CelebrationIcon from '@mui/icons-material/Celebration';

export default function ProjectCard({
  project,
  index,
  selected,
  setSelected,
}) {

  //Bool if app is running on mobile device
  const isMobile = window.innerWidth < 768;

  //Variable, States & Event handlers for 3D effect on mouseOver

  const powerOf3DEffect = 20;

  let hoverDefault = {
    x: 0,
    y: 0,
    on: false,
  };

  function formatDate(date) {
    const options = { month: 'long', year: 'numeric' };
    const [year, month] = date.split('-');
    const formattedDate = new Date(year, month - 1).toLocaleDateString('fr-FR', options);
    return formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);
  }

  const [hover, setHover] = useState(hoverDefault);

  const handleMouseEnter = (e) => {

    if (isMobile) return;

    let rect = e.target.getBoundingClientRect();
    setHover({
      x: (e.clientX - rect.left) / rect.width - 0.5,
      y: (e.clientY - rect.top) / rect.height - 0.5,
    });
  };

  const handleMouseMove = (e) => {

    if (isMobile) return;

    let rect = e.target.getBoundingClientRect();
    setHover({
      x: (e.clientX - rect.left) / rect.width - 0.5,
      y: (e.clientY - rect.top) / rect.height - 0.5,
      on: true,
    });
  };

  const handleMouseLeave = () => {

    if (isMobile) return;

    setHover(hoverDefault);
  };

  const handleOnClick = (e) => {
    setSelected(project);
    setHover(hoverDefault);
  };

  return (
    <div
      className="project-card-container"
      onMouseEnter={handleMouseEnter}
      onMouseMove={_.debounce(handleMouseMove, 2)}
      onMouseLeave={handleMouseLeave}
      onClick={handleOnClick}
    >
      <Card
        className="project-card"
        align="center"
        style={{
          transform: `perspective(2000px) rotateX(${hover.y * powerOf3DEffect}deg) rotateY(${-hover.x * powerOf3DEffect
            }deg)`,
          transition: `opacity 0s ${hover.on ? "" : ", transform 0.5s ease"}`,

          opacity: index === selected && "0",
          backgroundColor: 'rgba(0,0,0,0.6)',

        }}
        elevation={0}

      >
        <div className="project-image-container">
          <div
            className="project-image"
            style={{
              backgroundImage: `url(${project?.data?.gallery[0]?.original})`,
              width: "100%",
            }}
          />
        </div>
        <div className="project-body">
          <FormatedText text={project.data.name || ''} variant="h4" as="h3" fontWeight={300} mt={2} />
          <FormatedText text={project.data.subtitle || ''} variant="h5" as="p" fontWeight={300} mb={2} />
          <div className="project-infos">
            <div className="project-infos-first">
              {project?.data.numberOfParticipants > 0 &&
                <div className="project-invited-number">
                  <Tooltip title="Participants" placement="top">
                    <Chip icon={<PeopleAltIcon />} label={project?.data.numberOfParticipants} size="small" color="primary" />
                  </Tooltip>
                </div>
              }
              {project?.data.date &&
                <div className="project-date">
                  <Chip icon={<CalendarMonthIcon />} label={formatDate(project?.data?.date)} size="small" color="primary" />
                </div>
              }
              {project?.data.numberOfEvents > 0 &&
                <div className="project-invited-number">
                  <Tooltip title="Participants" placement="top">
                    <Chip icon={<CelebrationIcon />} label={project?.data.numberOfEvents} size="small" color="primary" />
                  </Tooltip>
                </div>
              }
            </div>
            {project?.data.missions &&
              <div className="project-missions">
                {project?.data.missions && project?.data.missions.split(',').map((item, index) => (
                  <Chip key={index} label={item} size="small" color="primary" />
                ))}
              </div>
            }
          </div>
          <FormatedText text={project.data.short_description || ''} p={2} />
        </div>
      </Card>
    </div>
  );
}
