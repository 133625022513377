import DeleteIcon from "@mui/icons-material/Delete";
import OpenWithIcon from "@mui/icons-material/OpenWith";
import { deleteObject, ref } from "@firebase/storage";
import { ButtonGroup, IconButton, Tooltip, useTheme } from "@mui/material";
import React from "react";
import "./SmartAsset.scss";
import { db, storage } from "../../database/firebase";
import { arrayRemove, doc, updateDoc } from "@firebase/firestore";
import { useHover } from '../../Hooks/useHover'

export default function SmartAsset({


  domainId,
  domainTitle,
  projectId,
  projectName,
  asset
}) {

  const deleteAsset = () => {
    
    //Update project's gallery's array

    //Doc ref
    let projectDoc = doc(db, "content", domainId, "projects", projectId)
    //Update it
    updateDoc(projectDoc, {
      gallery: arrayRemove(asset)
    })
    .catch(e => console.log(e.message))
    
    //Delete image file
    let itemRef = ref(storage, `images/domains/${domainTitle}/${projectName}/gallery/${asset?.id}`)
    deleteObject(itemRef);
  };

  return (
    <div className="gallery-asset">
      <img className="gallery-img" src={asset?.thumbnail} />
      <ButtonGroup className="asset-actions">
        <IconButton>
          <OpenWithIcon />
        </IconButton>
        <Tooltip title="Supprimer l'image">
          <IconButton onClick={deleteAsset} color="error">
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </ButtonGroup>
    </div>
  );
}
