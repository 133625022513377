import { collection, query, onSnapshot, orderBy } from "firebase/firestore";
import { useState, useEffect } from "react";
import { db } from "../database/firebase";

function usePublishedProjects(domainId) {
  const [projects, setProjects] = useState(null);

  useEffect(() => {

    const q = query(collection(db, "content", domainId, "projects"), orderBy("order"));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {

      const domainProjects = [];
      querySnapshot.forEach((doc) => {
        if (doc.data().published) {
          domainProjects.push({
            id: doc.id,
            data: doc.data(),
          });
        }

      });

      setProjects(domainProjects);
    });

    return () => unsubscribe();
  }, [domainId]);

  return projects;
}

export default usePublishedProjects;
