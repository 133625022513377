import { arrayRemove, doc, updateDoc } from "@firebase/firestore";
import { Button, TextField, Typography } from "@mui/material";
import { DataGrid, GridToolbar, frFR } from "@mui/x-data-grid";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import Subscribe from "../../../Components/Subscribe/Subscribe";
import { db } from "../../../database/firebase";
import { useDataAndDownloadCsv } from "./../../../Hooks/useDataAndDownloadCsv";
import "./Newsletter.scss";

export default function Newsletter() {

  const { enqueueSnackbar } = useSnackbar();
  const { data } = useDataAndDownloadCsv();


  //Delete subscribers by email
  const [unsubcribeEmail, setUnsubcribeEmail] = useState('');
  const handleEmailChange = (e) => {
    setUnsubcribeEmail(e.target.value);
  };

  const deleteByEmail = () => {
    function removeValue(value, index, arr) {
      if (value.email === unsubcribeEmail) {
        return true;
      }
      return false;
    }

    //Find entry that matches email
    let array = data;
    let dataToRemove = array.filter(removeValue);
    let newsletterDoc = doc(db, "newsletter", "LKVzB8zXo0WoR9GROd2S");

    //Remove every entry from database

    let count = 0
    
    dataToRemove.forEach((data) => {
      updateDoc(newsletterDoc, {
        subscribers: arrayRemove(data),
      })

        count++

    });

    //Reset state
    setUnsubcribeEmail('')

    //Notify admin how much entries were deleted
    enqueueSnackbar(`${count} abonnement(s) supprimé(s)`)
  };

  const columns = [
    { field: "familyName", headerName: "Nom", flex: 1, minWidth: 110 },
    { field: "givenName", headerName: "Prénom", flex: 1, minWidth: 110 },
    { field: "email", headerName: "Adresse mail", flex: 3, minWidth: 220 },
    {
      field: "timestamp",
      headerName: "Date d'inscription",
      sortComparator: (params) => {
        return params.row.timestamp;
      },
      valueGetter: (params) => {
        let date = new Date(params.row.timestamp);
        let frenchDate = `${date.getDate()}/${
          date.getUTCMonth() + 1
        }/${date.getFullYear()}`;
        return frenchDate;
      },
      flex: 1,
      minWidth: 130,
    },
  ];

  const rows = data.map((row, index) => {
    return { ...row, id: index };
  });

  return (
    <>
      <Typography align="center" color="text.primary" variant="h5" mb={5}>
        Abonnés à la newsletter
      </Typography>
      <div style={{ height: "100%", width: "100%" }}>
        <DataGrid
          autoHeight
          rows={rows}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[10]}
          checkboxSelection
          components={{
            Toolbar: GridToolbar,
          }}
          editMode="row"
          localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
        />
      </div>
      <Subscribe mode="admin" />
      <div className="delete-mail">
        <TextField
          id="order"
          label="Désinscrire un mail"
          value={unsubcribeEmail}
          onChange={handleEmailChange}
        />
        <Button onClick={deleteByEmail} color="error" variant="contained">
          Désinscrire
        </Button>
      </div>
    </>
  );
}
