import React from "react";
import { Typography } from "@mui/material";
import Emphasis from "./Emphasis";

const FormatedText = (props) => {
  let prevText = props.text || '';

  // Mise à jour de l'expression régulière pour capturer les sections avec balises et les caractères seuls
  const regex = /(!|_|#|\$|\*)(.*?)(\1)|([\p{L}\p{M}]+)|([\s\n,.'-])|./gu;

  let sections = prevText.match(regex);

  const formattedSections = sections?.map((section, index) => {
    if (section.length > 1) {
      const delimiter = section[0];
      const content = section.slice(1, -1);

      switch (delimiter) {
        case '*':
          return <span key={index} style={{ fontWeight: "bolder" }}>{content}</span>;
        case '_':
          return <span key={index} style={{ fontStyle: "italic" }}>{content}</span>;
        case '$':
          return (
            <Typography
              key={index}
              color="text.primary"
              style={{ whiteSpace: "pre-wrap" }}
              variant="h3"
              {...props}
            >
              {content}
            </Typography>
          );
        case '#':
          return (
            <img key={index} src={content} alt="" style={{
              maxWidth: "100%",
              maxHeight: "100%",
              objectFit: "cover"
            }}/>
          );
        case '!':
          return <Emphasis key={index} >{content}</Emphasis>;
        default:
          return section;
      }
    } else {
      return section;
    }
  });

  return (
    <>
      <Typography
        color="text.primary"
        style={{ whiteSpace: "pre-wrap" }}
        {...props}
      >
        {formattedSections}
      </Typography>
    </>
  );
};

export default FormatedText;
