import { doc, getDoc } from "@firebase/firestore";
import { useState, useEffect } from "react";
import { db } from "../database/firebase";

function usePage(pageId) {

    const [page, setPage] = useState(null)

    useEffect(() => {
        getDoc(doc(db, "pages", pageId))
        .then((pageDoc) => {
            setPage(pageDoc.data())
        })
        return () => {
            setPage(null)
        }
    }, [])


    return page
}

export default usePage