import { Button, ButtonGroup, Card, Collapse, Container, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField, Tooltip, Typography, useTheme } from "@mui/material";
import _ from "lodash";
import SettingsBackupRestoreIcon from "@mui/icons-material/SettingsBackupRestore";
import React, { useEffect, useState } from "react";
import './ContentPageCard.scss'
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { useSnackbar } from "notistack";
import { doc, updateDoc } from "@firebase/firestore";
import { db } from "../../database/firebase";
import FirebaseGallery from "../FirebaseGallery/FirebaseGallery";

export default function ContentPageCard({ page }) {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();

  //Data Handling
  const [pageEdit, setPageEdit] = useState(page.data);
  const [edited, setEdited] = useState(false);

  //Editor stuff
  const [editorOpen, setEditorOpen] = useState(false);

  const handleClose = () => {
    setEditorOpen(false);
  };

  useEffect(() => {
    setEdited(!_.isEqual(pageEdit, page.data));
  }, [pageEdit, page.data]);

  const [open, setOpen] = useState(false);
  const handleClick = () => {
    setOpen((prev) => !prev);
  };

  const handleTitleChange = (e) => {
    setPageEdit((prev) => ({
      ...prev,
      title: e.target.value,
    }));
  };
  const handleSubtitleChange = (e) => {
    setPageEdit((prev) => ({
      ...prev,
      subtitle: e.target.value,
    }));
  };
  const handleContentChange = (e) => {
    setPageEdit((prev) => ({
      ...prev,
      content: e.target.value,
    }));
  };

  //Save datas
  const savePage = () => {
    let docRef = doc(db, "pages", page.id);
    updateDoc(docRef, pageEdit)
      .catch(
        (e) =>
          enqueueSnackbar(
            `Impossible de sauvegarder les changements : ${e.message}`
          ),
        { variant: "error" }
      )
      .then(() => {
        enqueueSnackbar("Changements sauvegardés", { variant: "success" });
        setEdited(false);
      });
  };

  //RESET CHANGES
  const resetDatas = () => {
    setPageEdit(page.data);
  };

  return (
    <>
      <Card sx={{ p: 2 }}>
        <div className="content-page-head" onClick={handleClick}>
          <Typography variant="h6" p={1}>
            {pageEdit.title}
          </Typography>

          <Tooltip title={open ? "Fermer la page" : "Ouvrir la page"}>
            <IconButton>
              {open ? <RemoveCircleIcon /> : <AddCircleIcon />}
            </IconButton>
          </Tooltip>
        </div>
        <Collapse in={open} align="center">
          <div className="content-page-body" style={{ gap: theme.spacing(2) }}>
            <TextField
              id={`title-${page.id}`}
              label="Changer le titre"
              value={pageEdit.title}
              onChange={handleTitleChange}
            />
            <TextField
              id={`subtitle-${page.id}`}
              label="Changer le sous-titre"
              value={pageEdit.subtitle}
              onChange={handleSubtitleChange}
            />
            <TextField
              id={`content-${page.id}`}
              multiline
              label="Changer le contenu"
              value={pageEdit.content}
              onChange={handleContentChange}
            />
            {/* <Button variant="contained" onClick={() => setEditorOpen(true)} >Editeur</Button> */}
            <FirebaseGallery docId={page.id} />
          </div>
          <Container align="center" sx={{ p: theme.spacing(2) }}>
            <ButtonGroup variant="contained">
              <Button onClick={savePage} disabled={!edited}>
                Enregistrer
              </Button>
              <Tooltip
                title={
                  edited ? "Annuler les changements" : "Aucun changement"
                }
              >
                <span>
                  <IconButton disabled={!edited} onClick={resetDatas}>
                    <SettingsBackupRestoreIcon
                      color={edited ? "primary" : ""}
                    />
                  </IconButton>
                </span>
              </Tooltip>

            </ButtonGroup>
          </Container>
          <Button onClick={handleClick}>Fermer</Button>
        </Collapse>
      </Card>
      <Dialog open={editorOpen} onClose={handleClose} fullWidth>
        <DialogTitle>Editeur de page</DialogTitle>
        <DialogContent>
          <EditablePage />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Enregistrer</Button>
          <Button color="error" onClick={handleClose}>Fermer</Button>
        </DialogActions>
      </Dialog>


    </>
  );
}

const EditablePage = ({ page }) => {

  return (
    <div>
      {page && page.content.map((item, index) => {
        return (
          <div>

            <EditableItem item={item} key={index} />
          </div>
          ) 
      }
      )}
    </div>
  )
}

const EditableItem = ({ item }) => {
  switch (item.type) {
    case 'title':
      return <Typography variant="h1">{item.content}</Typography>
    case 'subtitle-h2':
      return <Typography variant="h2">{item.content}</Typography>
    case 'subtitle-h3':
      return <Typography variant="h3">{item.content}</Typography>
    case 'subtitle-h4':
      return <Typography variant="h4">{item.content}</Typography>
    case 'subtitle-h5':
      return <Typography variant="h5">{item.content}</Typography>
    case 'paragraph':
      return <Typography variant="body1">{item.content}</Typography>
    case 'image':
      return <img src={item.content} alt={item.alt} />
    default:
      return <Typography variant="body1">{item.content}</Typography>
  }
}