import { Button, ButtonGroup, Container, IconButton, TextField, Tooltip, Typography, useTheme } from "@mui/material";
import SettingsBackupRestoreIcon from "@mui/icons-material/SettingsBackupRestore";
import { MuiColorInput } from "mui-color-input";
import "./General.scss";
import _ from "lodash";
import { useEffect, useState } from "react";
import { doc, updateDoc } from "@firebase/firestore";
import { db } from "../../../database/firebase";
import { useSnackbar } from "notistack";

export default function General({appSettings}) {
  const [settings, setSettings] = useState(appSettings);
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setSettings(appSettings)
  }, [appSettings])

  const [edited, setEdited] = useState(false);

  useEffect(() => {
    setEdited(!_.isEqual(settings, appSettings));
  }, [settings, appSettings]);

  const handleTitleChange = (e) => {
    setSettings((prev) => ({
      general: {
        ...prev.general,
        title: e.target.value,
      },
    }));
  };

  const handleShortlineChange = (e) => {
    setSettings((prev) => ({
      general: {
        ...prev.general,
        shortline: e.target.value,
      },
    }));
  };

  const handleContactChange = (e) => {
    setSettings((prev) => ({
      general: {
        ...prev.general,
        contact: e.target.value,
      },
    }));
  };

  const handlePrimaryColorChange = (color) => {
    setSettings((prev) => ({
      general: {
        ...prev.general,
        palette: {
          ...prev.general?.palette,
          primary: color,
        },
      },
    }));
  };

  const handleSecondaryColorChange = (color) => {
    setSettings((prev) => ({
      general: {
        ...prev.general,
        palette: {
          ...prev.general?.palette,
          secondary: color,
        },
      },
    }));
  };

  const handleReinitialization = () => {
    if (!_.isEqual(settings, appSettings)) {
      setSettings(appSettings);
    }
  };

  const saveSettings = () => {
    let docRef = doc(db, "settings", "8s2JQswNNGzW5ylLr8FK")
    updateDoc(docRef, settings)
    .catch(
      (e) =>
        enqueueSnackbar(
          `Impossible de sauvegarder les changements : ${e.message}`
        ),
      { variant: "error" }
    )
    .then(() => {
      enqueueSnackbar("Changements sauvegardés", { variant: "success" });
      setEdited(false);
    });

  }

  return (
    <div>
      <Typography align="center" color="text.primary" variant="h5" mb={5}>
        Général
      </Typography>
      {settings.general && (
        <div className="general-settings-container">
          <TextField
            id="title"
            label="Titre du site"
            helperText={`Actuellement : ${appSettings.general?.title}`}
            value={settings.general?.title}
            onChange={handleTitleChange}
            sx={{
              width: "300px",
            }}
            focused={
              appSettings.general?.title !== settings.general?.title
            }
          />
          <TextField
            id="shortline"
            label="Shortline"
            helperText={`Actuellement : ${appSettings.general?.shortline}`}
            value={settings.general?.shortline}
            onChange={handleShortlineChange}
            sx={{
              width: "300px",
            }}
            focused={
              appSettings.general?.shortline !== settings.general?.shortline
            }
          />
          
          <TextField
            id="contact"
            label="Contact"
            helperText={`Actuellement : ${appSettings.general?.contact}`}
            value={settings.general?.contact}
            onChange={handleContactChange}
            sx={{
              width: "300px",
            }}
            focused={
              appSettings.general?.contact !== settings.general?.contact
            }
          />
          <MuiColorInput
            label="Couleur primaire"
            helperText={`Actuellement : ${appSettings.general?.palette?.primary}`}
            value={settings.general?.palette?.primary}
            onChange={handlePrimaryColorChange}
            format="hex"
            sx={{
              width: "300px",
            }}
            color="primary"
            focused={
              appSettings.general?.palette?.primary !==
              settings.general?.palette?.primary
            }
          />
          <MuiColorInput
            label="Couleur secondaire"
            helperText={`Actuellement : ${appSettings.general?.palette?.secondary}`}
            value={settings.general?.palette?.secondary}
            fallbackValue="#ffffff"
            onChange={handleSecondaryColorChange}
            format="hex"
            sx={{
              width: "300px",
            }}
            color="secondary"
            focused={
              appSettings.general?.palette?.secondary !==
              settings.general?.palette?.secondary
            }
          />
          <Container align="center" sx={{ p: theme.spacing(2) }}>
              <ButtonGroup variant="contained">
                <Button onClick={saveSettings} disabled={!edited}>
                  Enregistrer
                </Button>
                <Tooltip
                  title={
                    edited ? "Annuler les changements" : "Aucun changement"
                  }
                >
                  <span>
                    <IconButton disabled={!edited} onClick={handleReinitialization}>
                      <SettingsBackupRestoreIcon
                        color={edited ? "primary" : ""}
                      />
                    </IconButton>
                  </span>
                </Tooltip>
                
              </ButtonGroup>
            </Container>
        </div>
      )}
    </div>
  );
}
