import React, { useEffect } from "react";
import { Container, Fade, Typography, useTheme } from "@mui/material";

import "./Welcome.scss";

import Domain from "../../Components/Domain/Domain";
import FormatedText from "../../Components/FormatedText";
import usePage from "../../Hooks/usePage";

export default function Welcome(props) {
  const theme = useTheme();
  const page = usePage('welcome')

  useEffect(() => {
    document.title = props.title;
    props.setAppBG([]);
  }, [window.location.pathname]);

  return (
    <>
      <Container
        sx={{
          display: "flex",
          flexFlow: "column",
          justifyContent: "space-evenly",
          flex: 1,
          [theme.breakpoints.down('md')]: {
            gap: theme.spacing(3)
          }
        }}
      >
        <FormatedText
          text={page?.content}
          variant="h4"
          mb={1}
          align="center"
          sx={{ opacity: props.appBG[0] && "0", transition: "all 0.3s ease",
          [theme.breakpoints.down('md')]: {
            fontSize: '20px'
          }
        }}
        />
       
          <Container
            className="domains-container"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-evenly",
              flexWrap: "wrap",
              gap: theme.spacing(5),
            }}
          >
            {props.domains &&
              props.domains.map((domain) => {
                return (
                  <Fade in={true} key={domain.data.path} timeout={1000}>
                    <div
                      style={{
                        zIndex: props.appBG && "1",
                      }}
                    >
                      <Domain
                        title={domain.data.title}
                        path={domain.data.path}
                        image={domain.data.image}
                        appBG={props.appBG}
                        setAppBG={props.setAppBG}
                        shortDesc={domain.data.short_desc}
                      />
                    </div>
                  </Fade>
                );
              })}
          </Container>
          <Typography
            color="text.primary"
            variant="caption"
            as="p"
            align="center"
            sx={{
              letterSpacing: "12px",
              opacity: props.appBG[0] ? "0" : "0.4",
              transition: "all 0.3s ease",
              transform: "translateY(7.5dvh)",
              [theme.breakpoints.down("md")]: {
                letterSpacing: "5px",
              },
            }}
          >
            {props.appSettings?.general.shortline}
          </Typography>
        </Container>

    </>
  );
}
