import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Tooltip, Typography } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import React, { useState } from 'react'
import { deleteDoc, doc } from '@firebase/firestore'
import { deleteObject, listAll, ref } from '@firebase/storage'
import { db, storage } from '../../database/firebase'
import { useSnackbar } from 'notistack'

export default function DeleteDomain({domainId, domainTitle}) {

    const { enqueueSnackbar } = useSnackbar();

    const [open, setOpen] = useState(false)
    const handleClickOpen = () => {setOpen(true)}
    const handleClickClose = () => {setOpen(false)}

    const handleDeleteAlbum = () => {

        setOpen(false)

        let docRef = doc(db, "content", domainId)
        deleteDoc(docRef)

        .then(() => {
        let listRef = ref(storage, `images/domains/${domainTitle}`)
        listAll(listRef)
        .then((res) => {
            res.items.forEach((itemRef) => {
                deleteObject(itemRef)
            })
            res.prefixes.forEach((folderRef) => {
                listAll(folderRef)
                .then((res) => {
                    res.items.forEach((itemRef) => {
                        deleteObject(itemRef)
                    })
                    res.prefixes.forEach((folderRef) => {
                        listAll(folderRef)
                        .then((res) => {
                            res.items.forEach((itemRef) => {
                                deleteObject(itemRef)
                            })
                        })
                    })
                })
            })
        })
        })
        .catch((e) => {
            enqueueSnackbar(`Erreur : ${e}`, {variant: 'error'})
        })
        .then(() => {
        enqueueSnackbar(`Le domaine "${domainTitle}" a bien été supprimé.`, {variant: 'success'})
        })

      }

    return (
            <>
            <>
                <Tooltip title="Supprimer le domaine">
                    <IconButton color="error" onClick={handleClickOpen}>
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
            </>
            <Dialog
                open={open}
                onClose={handleClickClose}
                aria-labelledby="dialog-title"
                aria-describedby="dialog-content"
            >
                <DialogTitle id="dialog-title">
                    {`Supprimer le domaine ${domainTitle}`}
                </DialogTitle>
                <DialogContent id="dialog-content">
                    <Typography color="text.primary">{`Êtes vous sûr(e) de vouloir supprimer le domaine "${domainTitle}" ?`}</Typography>
                </DialogContent>
                <DialogActions>
                    <Button variant="filled" onClick={handleClickClose}>Annuler</Button>
                    <Button variant="contained" color="error" onClick={handleDeleteAlbum}>Confirmer</Button>
                </DialogActions>
            </Dialog>
            </>
    )
}
