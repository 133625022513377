import { doc, onSnapshot } from "@firebase/firestore";
import { useState, useEffect } from "react";
import { db } from "../database/firebase";

function useGallery(docId) {

    const [gallery, setGallery] = useState(null)

    useEffect(() => {
        const unsubscribe = onSnapshot(doc(db, "galleries", docId) , (doc) => {
            setGallery(doc.data().images)
        })
        return () => {
            setGallery(null)
            unsubscribe()
        }
    }, [])


    return gallery
}

export default useGallery