import { useTheme } from '@mui/material'
import { motion } from 'framer-motion'
import React from 'react'
import './DomainBG.scss'

export default function DomainBG(props) {

    const theme = useTheme()

    const bg = props.appBG
    
    return (
        <>
           {    props.appBG &&
                
                <motion.div
                    className="domainBG"
                    style={{
                        backgroundImage: `url(${bg})`,
                        filter: `brightness(0.3)`,
                    }}
                    key = "appBG"
                    
                    initial = {{
                        opacity: 0
                    }}

                    animate = {{
                        opacity: 1
                    }}
                    
                    exit = {{
                        opacity: 0
                    }}
                    transition={{duration: 0.3}}
                />
            }
        </>
    )
}
