import { collection, query, onSnapshot, orderBy } from "firebase/firestore";
import { useState, useEffect } from "react";
import { db } from "../database/firebase";

function usePages() {
  const [pages, setPages] = useState(null);

  useEffect(() => {
    const q = query(collection(db, "pages"), orderBy("order"));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const appPages = [];
      querySnapshot.forEach((doc) => {
        appPages.push({
          id: doc.id,
          data: doc.data(),
        });
      });
      setPages(appPages);
    });

    return () => unsubscribe();
  }, []);

  return pages;
}

export default usePages;
