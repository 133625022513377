import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";

import Welcome from "./Pages/Welcome/Welcome";
import { createTheme, responsiveFontSizes, ThemeProvider } from "@mui/material";
import Legal from "./Pages/Legal/Legal";
import { useEffect, useState } from "react";
import { SnackbarProvider } from "notistack";
import LogIn from "./Pages/LogIn/LogIn";
import Oups from "./Pages/Oups/Oups";
import { onAuthStateChanged } from "@firebase/auth";
import { auth, db } from "./database/firebase";
import Admin from "./Pages/Admin/Admin";
import About from "./Pages/About/About";
import DomainBG from "./Components/DomainBG/DomainBG";
import { AnimatePresence } from "framer-motion";
import DomainPage from "./Pages/DomainPage/DomainPage";
import Contact from "./Pages/Contact/Contact";
import Profile from "./Pages/Admin/Profile/Profile";
import Layout from "./layout/Layout/Layout";
import General from "./Pages/Admin/General/General";
import Content from "./Pages/Admin/Content/Content";
import { doc, getDoc } from "@firebase/firestore";

import Newsletter from "./Pages/Admin/Newsletter/Newsletter";

import usePublishedDomains from "./Hooks/usePublishedDomains";
import ConsentBanner from "./Components/ConsentBanner/ConsentBanner";

function App() {

  // Fetching datas

  const [user, setUser] = useState();

  const [userDatabase, setUserDatabase] = useState({});

  onAuthStateChanged(auth, (user) => {
    if (user) {
      setUser(user);
    } else {
      setUser(null);
    }
  });



  const [appSettings, setAppSettings] = useState({
    general: {
      title: '',
      description: '',
      shortline: '',
      contact: '',
      palette: {
        primary: '#FFF',
        secondary: '#FFF',
      }
    }
  });

  const getUser = () => {
    if (auth.currentUser) {
      setUser(auth.currentUser);
    }
  };

  const getUserDatabase = (user) => {
    let docRef = doc(db, "users", user.uid);
    getDoc(docRef).then((doc) => setUserDatabase(doc.data()));
  };


  const getSettings = () => {
    let docRef = doc(db, "settings", "8s2JQswNNGzW5ylLr8FK");
    getDoc(docRef)
      .catch((e) => console.log(e))
      .then((doc) => {
        let settings = doc.data()
        setAppSettings(settings);
      });
  };

  useEffect(() => {

    getSettings();
    return () => {

      setAppSettings({});
    };
  }, []);

  const domains = usePublishedDomains()

  useEffect(() => {
    if (user) {
      getUser();
      getUserDatabase(user);
    }
  }, [user]);

  const darkPref =
    window.matchMedia &&
    window.matchMedia("(prefers-color-scheme: dark)").matches;

  const [appBG, setAppBG] = useState([]);

  const theme = responsiveFontSizes(
    createTheme({
      palette: {
        mode: darkPref ? "dark" : appBG[0] ? "dark" : "light",
        primary: {
          main: appSettings?.general?.palette?.primary || "#FFF",
        },
        secondary: {
          main: appSettings?.general?.palette?.secondary || "#FFF",
        },
      },
    })
  )



  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <SnackbarProvider maxSnack={3}>
          <div
            className="App"
            id="App"
            style={{
              border: `solid ${window.innerWidth > 768 ? "20" : "10"}px ${theme.palette.mode === "light" ? "black" : "white"
                }`,
              background: theme.palette.mode === "light" ? "white" : "black",
              minHeight: "100vh",
            }}
          >
            <ConsentBanner />
            <AnimatePresence>
              {appBG &&
                appBG.map((image, index) => {
                  return <DomainBG key={index} appBG={image} />;
                })}
            </AnimatePresence>

            <Routes>
              <Route
                path="/"
                element={
                  <Layout
                    user={user}
                  />
                }
              >
                <Route
                  index
                  path=""
                  element={
                    <Welcome
                      title="Proud Projects"
                      appBG={appBG}
                      setAppBG={setAppBG}
                      domains={domains}
                      appSettings={appSettings}
                    />
                  }
                />
                {domains && domains.map((domain) => {
                  return (
                    <Route
                      key={domain.data.title}
                      path={domain.data.path}
                      element={
                        <DomainPage domain={domain} setAppBG={setAppBG} />
                      }
                    />
                  );
                })}
                <Route
                  path="legal"
                  element={<Legal title="Proud Projects - Mentions légales" />}
                />
                <Route
                  path="about"
                  element={
                    <About
                      title="Proud Projects - À propos de Proud Projects"
                      domains={domains}
                      setAppBG={setAppBG}
                    />
                  }
                />
                <Route
                  path="contact"
                  element={<Contact title="Proud Projects - Contact" setAppBG={setAppBG} email={appSettings?.general?.contact} />}

                />
                <Route
                  path="*"
                  element={<Oups title="Proud Projects - Oups" />}
                />
              </Route>
              <Route
                path="/ppa"
                element={
                  user ? (
                    <Admin title="Test" user={user} setAppBG={setAppBG} />
                  ) : (
                    <LogIn title="Proud Projects - Connexion" />
                  )
                }
              >
                <Route
                  index
                  path=""
                  element={<General title="test" appSettings={appSettings} />}
                />
                <Route
                  path="content"
                  element={<Content />}
                >
                  {domains && domains.map((domain) => {
                    return (
                      <Route
                        key={domain.data.title}
                        path={domain.data.path}
                        element={
                          <DomainPage domain={domain} setAppBG={setAppBG} />
                        }
                      />
                    );
                  })}
                </Route>
                <Route
                  path="newsletter"
                  element={
                    <Newsletter
                      title="Newsletter"
                    />
                  }
                />
                <Route
                  path="profile"
                  element={
                    <Profile
                      title="Mon profil"
                      user={user}
                      userDatabase={userDatabase}
                      setUser={setUser}
                      setUserDatabase={setUserDatabase}
                    />
                  }
                />
              </Route>

            </Routes>
          </div>
        </SnackbarProvider>
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
