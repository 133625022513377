import { Collapse, Typography, useTheme } from "@mui/material";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import FormattedText from "../FormatedText";
import "./Domain.scss";

export default function Domain(props) {
  const theme = useTheme();

  const [hover, setHover] = useState(false);

  const handleMouseEnter = () => {
    setHover(true);
    props.setAppBG((prev) => [...prev, props.image]);
  };
  const handleMouseLeave = () => {
    setHover(false);
    setTimeout(() => {
      props.setAppBG((prev) => prev.filter((image) => image !== props.image));
    }, 200);
  };

  return (
    <>
      <Link
        className="domain-link"
        to={props.path}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        style={{
          backgroundColor: hover ? "rgba(0,0,0,0.2)" : "rgba(0,0,0,0)",
        }}
      >
        <div
          className="domain"
          style={{
            border: `solid 2px ${
              hover ? theme.palette.primary.main : theme.palette.text.primary
            }`,
            transition: "all 0.5s ease",
          }}
        >
          <Typography variant="h5" color="text.primary">
            {props.title}
          </Typography>
        </div>
        <Collapse
          in={hover}
          className="collapse-short-description"
          sx={{
            borderColor: hover
              ? theme.palette.primary.main
              : theme.palette.text.primary,
          }}
        >
          <FormattedText
            text={props.shortDesc}
            variant="caption"
            as="div"
            align="center"
            p={2}
          />
        </Collapse>
      </Link>
    </>
  );
}
