import { useEffect, useRef } from "react";

export function useClickOutside(callback) {
  const outsideClickRef = useRef(null);
  
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (outsideClickRef.current && !outsideClickRef.current.contains(event.target)) {   
                callback()
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        document.addEventListener("pointerdown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
            document.removeEventListener("pointerdown", handleClickOutside);
        };
    }, [outsideClickRef.current]);

  return outsideClickRef;
}
