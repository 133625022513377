import React from "react";
import "./ConsentBanner.scss";
import { useState } from "react";
import { Button, Typography, useTheme, ButtonGroup } from "@mui/material";
import { analytics } from "../../database/firebase";

export default function ConsentBanner() {
  // Handle Cookies consent
  const [cookiesConsent, setCookiesConsent] = useState(
    localStorage.getItem("cookiesConsent")
  );

  

  const theme = useTheme();

  const handleCookiesConsent = (bool) => {
    localStorage.setItem("cookiesConsent", bool);
    setCookiesConsent(bool);
    analytics.setAnalyticsCollectionEnabled(bool)
  };

  return (
    <>
   { cookiesConsent === null ? (

    <div
      className="cookies-consent"
      style={{
        padding: theme.spacing(2),
        gap: theme.spacing(1),
      }}
    >
      <Typography align="center" color="text.primary">
        Nous utilisons des cookies pour améliorer votre expérience de navigation
        et pour nous aider à comprendre comment vous utilisez notre site.
      </Typography>

      <ButtonGroup>
        <Button color="primary" variant="contained" onClick={() => {handleCookiesConsent(true)}}>
          Accepter
        </Button>
        <Button color="error" onClick={() => {handleCookiesConsent(false)}}>
          Refuser
        </Button>
      </ButtonGroup>
    </div>
   ) : null} 
    </>
  );
}
