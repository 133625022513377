import { Container } from '@mui/material'
import React, { useEffect } from 'react'
import FormatedText from '../../Components/FormatedText'
import usePage from '../../Hooks/usePage'
import './Legal.scss'

export default function Legal(props) {

    const page = usePage('legal')

    useEffect(() => {
        let oldTitle = document.title
        document.title = page?.title
        return () => {
          document.title=oldTitle
        }
      }, [page?.title])

    return (
        <Container>
            <Container>
                <Container>
                    <FormatedText text={page?.title} variant="h2" mt={2} mb={3} />
                    <FormatedText text={page?.subtitle} variant="h3" as="p" mt={1} mb={2} />
                    <FormatedText text={page?.content} />
                </Container>
            </Container>
        </Container>
    )
}
