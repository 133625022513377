import {
  Button,
  ButtonGroup,
  Card,
  Collapse,
  Container,
  FormControlLabel,
  IconButton,
  Switch,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import "./ContentDomainCard.scss";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import "./ContentDomainCard.scss";
import ContentDomainProjectCard from "../ContentDomainProjectCard/ContentDomainProjectCard";
import SettingsBackupRestoreIcon from "@mui/icons-material/SettingsBackupRestore";
import _ from "lodash";
import CreateProjectDialog from "../CreateProjectDialog/CreateProjectDialog";
import DeleteDomain from "../DeleteDomain/DeleteDomain";
import {} from "../../";
import {
  deleteObject,
  getDownloadURL,
  listAll,
  ref,
  uploadBytes,
} from "@firebase/storage";
import { db, storage } from "../../database/firebase";
import { doc, updateDoc } from "@firebase/firestore";
import useProjects from "../../Hooks/useProjects";
import { useSnackbar } from "notistack";

export default function ContentDomainCard(props) {
  const theme = useTheme();

  const { enqueueSnackbar } = useSnackbar();

  //OPEN / CLOSE CARD
  const [open, setOpen] = useState(false);

  //Load projects
  const projects = useProjects(props.domain.id);

  //Open/Close handler
  const handleCardClick = () => {
    setOpen((prev) => !prev);
  };

  // DATAS HANDLING
  const [domain, setDomain] = useState(props.domain.data);
  const [edited, setEdited] = useState(false);

  // MAIN IMAGE HANDLING
  const [image, setImage] = useState(null);
  const [imgUrls, setImgUrls] = useState({
    preview: "",
    download: "",
  });

  const addImage = () => {
    //Delete any file in the domain's main image folder
    let listRef = ref(storage, `images/domains/${domain.title}/image/`);
    listAll(listRef).then((res) => {
      res.items?.forEach((itemRef) => {
        deleteObject(itemRef)
      });
    });

    //Image Upload & url ref to Domain
    let mainImageRef = ref(
      storage,
      `images/domains/${domain.title}/image/${image.name}`
    );
    uploadBytes(mainImageRef, image).then(() => {
      getDownloadURL(mainImageRef).then((url) => {
        //Update State for rendering
        setImgUrls((prev) => ({
          ...prev,
          download: url,
        }));
        setDomain((prev) => ({
          ...prev,
          image: url,
        }));
        setImage(null);

        //Update Firebase document
        let docRef = doc(db, "content", props.domain.id);
        updateDoc(docRef, {
          image: url,
        });
      });
    });
  };

  const handleImageChange = (e) => {
    let imageUrl = URL.createObjectURL(e.target.files[0]);
    setImage(e.target.files[0]);
    setImgUrls((prev) => ({
      ...prev,
      preview: imageUrl,
    }));
  };

  const handleOrderChange = (e) => {
    setDomain((prev) => ({
      ...prev,
      order: parseInt(e.target.value),
    }));
  };
  const handleTitleChange = (e) => {
    setDomain((prev) => ({
      ...prev,
      title: e.target.value,
    }));
  };
  const handleSubtitleChange = (e) => {
    setDomain((prev) => ({
      ...prev,
      subtitle: e.target.value,
    }));
  };
  const handlePublishedChange = (e) => {
    setDomain((prev) => ({
      ...prev,
      published: e.target.checked,
    }));
  };

  const handlePathChange = (e) => {
    setDomain((prev) => ({
      ...prev,
      path: e.target.value,
    }));
  };
  const handleShortDescChange = (e) => {
    setDomain((prev) => ({
      ...prev,
      short_desc: e.target.value,
    }));
  };
  const handleLongDescChange = (e) => {
    setDomain((prev) => ({
      ...prev,
      long_desc: e.target.value,
    }));
  };

  const handleAboutDescChange = (e) => {
    setDomain((prev) => ({
      ...prev,
      about_desc: e.target.value,
    }));
  }

  useEffect(() => {
    setEdited(!_.isEqual(domain, props.domain.data));
  }, [domain, props.domain.data]);

  //RESET CHANGES
  const resetDatas = () => {
    setDomain(props.domain.data);
  };

  //SAVE CHANGES
  const saveDomain = () => {
    let docRef = doc(db, "content", props.domain.id);
    updateDoc(docRef, domain)
      .catch(
        (e) =>
          enqueueSnackbar(
            `Impossible de sauvegarder les changements : ${e.message}`
          ),
        { variant: "error" }
      )
      .then(() => {
        enqueueSnackbar("Changements sauvegardés", { variant: "success" });
        setEdited(false);
      });
  };

  //DIALOG FOR PROJECT CREATION
  const [openAddProject, setOpenAddProject] = useState(false);
  const handleOpenAddProject = () => {
    setOpenAddProject((prev) => !prev);
  };

  return (
    <>
      <Card
        key={props.domain.id}
        className="content-domain-card"
        elevation={8}
        sx={{
          cursor: open ? "auto" : "pointer",
        }}
      >
        <div className="card-header" onClick={handleCardClick}>
          <Typography align="center" color="text.primary" variant="h5">
            {domain.title}
          </Typography>
          <Tooltip title={open ? "Fermer le domaine" : "Ouvrir le domaine"}>
            <IconButton>
              {open ? <RemoveCircleIcon /> : <AddCircleIcon />}
            </IconButton>
          </Tooltip>
          <Typography fontSize="3em" color="GrayText" className="domain-order">
            {domain.order}
          </Typography>
        </div>
        <Collapse in={open}>
          <div className="content-domain-card-inputs">
            <FormControlLabel
              control={
                <Switch
                  checked={domain.published ? true : false}
                  onChange={handlePublishedChange}
                />
              }
              label="En ligne"
            />
            
            <TextField
              id="order"
              label="Ordre"
              type="number"
              value={domain.order}
              onChange={handleOrderChange}
            />
            <TextField
              id="title"
              label="Titre"
              value={domain.title}
              onChange={handleTitleChange}
            />
            <TextField
              id="subtitle"
              label="Sous-Titre"
              value={domain.subtitle}
              onChange={handleSubtitleChange}
            />
            <div className="content-domain-card-image">
              <Collapse in={domain.image ? true : false}>
                <Typography variant="caption" as="p" align="center">
                  Image du domaine
                </Typography>
                <img
                  src={domain.image}
                  alt={domain.title}
                  style={{
                    borderRadius: theme.shape.borderRadius,
                    borderColor: theme.palette.primary.main,
                  }}
                />
              </Collapse>
              <Button component="label" sx={{ margin: "auto" }}>
                {props.domain.data.image ? "Changer d'" : "Choisir une "}image
                principale
                <input
                  type="file"
                  name="project-image"
                  id="project-image"
                  hidden
                  accept="image/png, image/gif, image/jpeg"
                  onChange={handleImageChange}
                />
              </Button>
            </div>
            <Collapse in={image ? true : false}>
              <div className="image-preview">
                <Typography align="center" variant="caption">
                  Prévisualisation image principale :
                </Typography>
                <img
                  src={imgUrls.preview}
                  alt="Prévisualisation de la selection"
                  style={{
                    border: `solid 1px ${theme.palette.primary.main}`,
                    borderRadius: theme.shape.borderRadius,
                  }}
                />
                <ButtonGroup>
                  <Button color="error" onClick={() => setImage(null)}>
                    Annuler
                  </Button>
                  <Button
                    color="success"
                    variant="contained"
                    onClick={addImage}
                  >
                    Confirmer
                  </Button>
                </ButtonGroup>
              </div>
            </Collapse>
            <TextField
              id="path"
              multiline
              label="Adresse Url"
              helperText={`proud-projects.com/${props.domain.data.path}`}
              value={domain.path}
              onChange={handlePathChange}
            />
            <TextField
              id="short_desc"
              multiline
              label="Description courte"
              value={domain.short_desc}
              onChange={handleShortDescChange}
            />
            <TextField
              id="longz_desc"
              multiline
              label="Description longue"
              value={domain.long_desc}
              onChange={handleLongDescChange}
            />
            <TextField
              id="about_desc"
              multiline
              label="Description About"
              value={domain.about_desc}
              onChange={handleAboutDescChange}
            />
          </div>
          <Typography align="center" color="text.primary" p={2}>
            Projets
          </Typography>
          <div className="domain-projects">
            {
              //PROJETS DU DOMAINE
              projects?.map((project, index) => {
                return (
                  <ContentDomainProjectCard
                    key={project.id}
                    initialProject={project.data}
                    projectId={project.id}
                    domainTitle={props.domain.data.title}
                    domainId={props.domain.id}
                  />
                );
              })
            }
            <Button onClick={handleOpenAddProject}>Ajouter un projet</Button>
          </div>
          <Container align="center" sx={{ p: theme.spacing(2) }}>
            <ButtonGroup variant="contained">
              <Button onClick={saveDomain} disabled={!edited}>
                Enregistrer
              </Button>
              <Tooltip
                title={edited ? "Annuler les changements" : "Aucun changement"}
              >
                <span>
                  <IconButton disabled={!edited} onClick={resetDatas}>
                    <SettingsBackupRestoreIcon
                      color={edited ? "primary" : ""}
                    />
                  </IconButton>
                </span>
              </Tooltip>
              <DeleteDomain
                domainId={props.domain.id}
                domainTitle={props.domain.data.title}
              />
            </ButtonGroup>
          </Container>
          <Typography
            align="center"
            color="text.secondary"
            variant="caption"
            as="p"
          >
            id : {props.domain.id}
          </Typography>
        </Collapse>
      </Card>
      <CreateProjectDialog
        open={openAddProject}
        close={handleOpenAddProject}
        domainTitle={props.domain.data.title}
        domainId={props.domain.id}
      />
    </>
  );
}
