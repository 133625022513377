import DeleteIcon from "@mui/icons-material/Delete";
import OpenWithIcon from "@mui/icons-material/OpenWith";
import { deleteObject, getDownloadURL, ref } from "@firebase/storage";
import { ButtonGroup, IconButton, Tooltip, useTheme, Dialog } from "@mui/material";
import React from "react";
import "./FirebaseImage.scss";
import { db, storage } from "../../database/firebase";
import { arrayRemove, doc, getDoc, updateDoc } from "@firebase/firestore";
export default function FirebaseImage({
  asset,
  docId
}) {
  const theme = useTheme();

  const deleteAsset = () => {
    
    //Update project's gallery's array

    //Doc ref
    let docRef = doc(db, "galleries", docId)
    //Update it
    updateDoc(docRef, {
      images: arrayRemove(asset)
    })
    .catch(e => console.log("Erreur dans la mise à jour du document associé : ", e.message))
    
    //Delete image file
    let itemRef = ref(storage, `images/galleries/${docId}/${asset?.id}`)
    deleteObject(itemRef);
  };

  return (
    <>
    <div className="gallery-asset">
      <img className="gallery-img" src={asset?.thumbnail}/>
      <ButtonGroup className="asset-actions">
        <IconButton>
          <OpenWithIcon />
        </IconButton>
        <Tooltip title="Supprimer l'image">
          <IconButton onClick={deleteAsset} color="error">
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </ButtonGroup>
    </div>
    </>
  );
}
