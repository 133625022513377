import React, { useState } from 'react'


export default function Letter(props) {

    const [hover, setHover] = useState(false)
    const [transform, setTransform] = useState("0px")

    const handleHover = () => {
        if (!hover) {
            setTransform("-50%")
            setTimeout(() => {
                setTransform("0%")
            }, 200)
        }
        setHover(!hover)
    }

    return (
        <span
            onMouseEnter={handleHover}
            onMouseLeave={handleHover}
            style={{

                minWidth: '0.2em',

                display: 'inline-block',
                transform: `translateY(${transform})`,

                transition: 'all .5s ease'

            }}
        >
            {props.children}
        </span>
    )
}
