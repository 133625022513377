import {
  BottomNavigation,
  BottomNavigationAction,
  Paper,
} from "@mui/material";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import AbcIcon from "@mui/icons-material/Abc";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import NewspaperIcon from '@mui/icons-material/Newspaper';
import LanguageIcon from '@mui/icons-material/Language';
import React, { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router";
import "./Admin.scss";
import { Link } from "react-router-dom";

export default function Admin() {
  document.title = "Proud Projects - Panneau d'administration";
  const location = useLocation()
  const [value, setValue] = useState(location.pathname);

  //Remove border of App when user is in admin pannel
  useEffect(() => {
    let App = document.querySelector('#App')
    let oldBorder = App.style.border
    App.style.border = ''
    //Put it back on admin pannel leaving
    return () => {
      App.style.border = oldBorder
    }
  }, [])

  return (
    <div className="admin-pannel">
      <Paper
        sx={{
          position: "fixed",
          bottom: "0",
          left: "0",
          right: "0",
          zIndex: "667",
        }}
        elevation={3}
      >
        <BottomNavigation
          showLabels
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
          value={value}
        >
          <BottomNavigationAction
            label="Général"
            icon={<AdminPanelSettingsIcon />}
            component={Link}
            to="/ppa"
            value="/ppa"
          />
          <BottomNavigationAction
            label="Contenu"
            icon={<AbcIcon />}
            component={Link}
            to="/ppa/content"
            value="/ppa/content"
          />
          <BottomNavigationAction

            label="Newsletter"
            icon={<NewspaperIcon />}
            component={Link}
            to="/ppa/newsletter"
            value="/ppa/newsletter"
          />
          <BottomNavigationAction
            label="Site"
            icon={<LanguageIcon />}
            component={Link}
            to="/"
            value="/"
          />
          <BottomNavigationAction
            label="Profil"
            icon={<AccountCircleIcon />}
            component={Link}
            to="/ppa/profile"
            value="/ppa/profile"
          />
        </BottomNavigation>
      </Paper>
      <Outlet />
    </div>
  );
}
