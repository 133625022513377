import {
  Alert,
  Button,
  Collapse,
  Container,
  Fade,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import ContentDomainCard from "../../../Components/ContentDomainCard/ContentDomainCard";
import CreateDomainDialog from "../../../Components/CreateDomainDialog/CreateDomainDialog";
import useDomains from "../../../Hooks/useDomains";
import usePages from "../../../Hooks/usePages";
import { TransitionGroup } from "react-transition-group";

import "./Content.scss";
import Emphasis from "../../../Components/Emphasis";
import ContentPageCard from "../../../Components/ContentPageCard/ContentPageCard";

export default function Content(props) {
  const [createDomainDialog, setCreateDomainDialog] = useState(false);

  const domains = useDomains();

  const pages = usePages();

  const handleCreateDomainDialog = () => {
    setCreateDomainDialog((prev) => !prev);
  };

  return (
    <div className="content-domains-container">
      <Typography align="center" color="text.primary" mb={2} variant="h5">
        Contenu du site
      </Typography>

      <Typography align="center" color="text.primary" mb={1} variant="h6">
        Domaines d'activité
      </Typography>

      <TransitionGroup
        appear
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: "15px",
          alignItems: "center",
        }}
      >
        {domains?.map((domain) => (
          <Collapse key={domain.id} sx={{ width: "100%", maxWidth: "900px" }}>
            <ContentDomainCard domain={domain} key={domain.id} />
          </Collapse>
        ))}


        

        <Fade>
          <Button variant="contained" onClick={handleCreateDomainDialog}>
            Ajouter un domaine
          </Button>
        </Fade>
        <Fade>
          <Typography align="center" color="text.primary" mb={1} variant="h6">
            Pages
          </Typography>
        </Fade>

        {pages?.map((page) => (
          <Collapse key={page.id} sx={{ width: "100%", maxWidth: "900px" }}>
            <ContentPageCard page={page} />
          </Collapse>
        ))}
      </TransitionGroup>
      <CreateDomainDialog
        open={createDomainDialog}
        close={handleCreateDomainDialog}
        domains={domains}
      />
      <Container maxWidth="md" sx={{ mt: 5 }}>
        <Alert severity="info">
          Dans les champs textuels, vous pouvez entourer un mot pour le modifier
          :
          <br /> *<span style={{ fontWeight: "bolder" }}>Proud Projects</span>*
          <br /> _<span style={{ fontStyle: "italic" }}>Proud Projects</span>_
          <br /> !<Emphasis>Proud Projects</Emphasis>!
          <br />  <Typography
          color="text.primary"
          style={{ whiteSpace: "pre-wrap" }}
          variant="h5" as="p"
          {...props}
        >
          $Sous-Titre$
        </Typography>
        </Alert>
      </Container>
    </div>
  );
}
