import {
  Box,
  Button,
  ButtonGroup,
  Container,
  Dialog,
  InputAdornment,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useRef, useState } from "react";
import {
  addDoc,
  collection,
  getDocs,
  limit,
  orderBy,
  query,
} from "firebase/firestore";
import { db, storage } from "../../database/firebase";
import "./CreateDomainDialog.scss";
import { getDownloadURL, ref, uploadBytes } from "@firebase/storage";

export default function CreateDomainDialog(props) {
  const theme = useTheme();

  const [image, setImage] = useState(null);
  const [imgUrls, setImgUrls] = useState({
    preview: "",
    download: "",
  });

  // Input Refs

  const [title, setTitle] = useState("");
  const [path, setPath] = useState("");
  const [titleExist, setTitleExist] = useState(false);
  const [pathExist, setPathExist] = useState(false);

  const handleTitleChange = (e) => {
    setTitle(e.target.value);

    //Prevent title to be the same than another document's
    let titleFound = false;

    props.domains.forEach((domain) => {
      if (domain.data.title === e.target.value && !titleFound) {
        setTitleExist(true);
        titleFound = true;
        console.error(
          "Un document portant ce nom existe déjà dans votre base de donnée"
        );
        return;
      }

      if (domain.data.title !== e.target.value && !titleFound) {
        setTitleExist(false);
      }
    });

    //Change path value

    let newValue = e.target.value
      .toLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .replaceAll(" ", "-");

    setPath(newValue);
    //Prevent path to be the same than another document's
    let pathFound = false;

    props.domains.forEach((domain) => {
      if (domain.data.path === newValue && !pathFound) {
        setPathExist(true);
        pathFound = true;
        console.error(
          "Un document portant ce chemin existe déjà dans votre base de donnée"
        );
        return;
      }

      if (domain.data.path !== path && !pathFound) {
        setPathExist(false);
      }
    });
  };
  const shortDescRef = useRef(null);
  const longDescRef = useRef(null);

  const onPathChange = (e) => {
    //Prevent path to be the same than another document's
    let newValue = e.target.value
      .toLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .replaceAll(" ", "-");
    let pathFound = false;
    setPath(newValue);

    props.content.forEach((domain) => {
      if (domain.data.path === newValue && !pathFound) {
        setPathExist(true);
        pathFound = true;
        console.error(
          "Un document portant ce chemin existe déjà dans votre base de donnée"
        );
        return;
      }

      if (domain.data.path !== e.target.value && !pathFound) {
        setPathExist(false);
      }
    });
  };

  const handleImageChange = (e) => {
    let imageUrl = URL.createObjectURL(e.target.files[0]);
    setImage(e.target.files[0]);
    setImgUrls((prev) => ({
      ...prev,
      preview: imageUrl,
    }));
  };

  // On path value change : check if path already exist

  const addDomain = (published) => {
    //get existings docs max order
    const q = query(
      collection(db, "content"),
      orderBy("order", "desc"),
      limit(1)
    );

    getDocs(q).then((documents) => {
      let maxIndex = 0;
      documents.forEach((doc) => {
        maxIndex = doc.data().order;
      });
      //Creating domain array
      let domain = {
        title: title,
        path: path,
        short_desc: shortDescRef?.current.value,
        long_desc: longDescRef?.current.value,
        image: "",
        projects: [],
        published: published,
        order: maxIndex + 1,
      };
      //If exist, add  image file to storage
      if (image) {
        //Ref
        let storageRef = ref(storage, `images/domains/${title}/${image.name}`);
        //Upload image
        uploadBytes(storageRef, image).then((snapshot) => {
          getDownloadURL(storageRef).then((url) => {
            //Domain array with image url
            domain.image = url;
            addDoc(collection(db, "content"), domain);
          });
        });
      } else {
        addDoc(collection(db, "content"), domain);
      }
      setImage(null);
      setTitle("");
      setPath("");
    });
  };

  return (
    <Dialog open={props.open} onClose={props.close}>
      <Container width="100%" maxWidth="xl">
        <div
          className="create-domain"
          style={{
            padding: theme.spacing(4),
          }}
        >
          <Typography color="text.primary" variant="h5" mb={2}>
            Ajouter un domaine d'activité
          </Typography>
          <TextField
            focused={titleExist}
            label="Titre"
            value={title}
            onChange={handleTitleChange}
            color={titleExist ? "error" : "primary"}
            helperText={
              titleExist && (
                <Typography variant="caption" color="error">
                  ⚠️ Un domaine d'activité existant possède le même titre
                </Typography>
              )
            }
          />
          <TextField
            focused={pathExist}
            value={path}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  proud-projects.com/
                </InputAdornment>
              ),
            }}
            onChange={onPathChange}
            color={pathExist ? "error" : "primary"}
            helperText={
              pathExist ? (
                <Typography variant="caption" color="error">
                  ⚠️ Un domaine d'activité existant possède le même chemin
                </Typography>
              ) : (
                <>
                  Définir l'adresse URL de ce domaine.
                  <br />
                  Exemple : proud-projects.com/
                  {title
                    .toLowerCase()
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
                    .replaceAll(" ", "-")}
                </>
              )
            }
            label="Adresse Url"
          />
          <TextField
            label="Description courte"
            inputRef={shortDescRef}
            multiline
          />
          <TextField
            label="Description longue"
            inputRef={longDescRef}
            multiline
          />
          <Button component="label" variant="contained">
            {image ? "Changer d'" : "Choisir une "}image
            <input
              type="file"
              name="domain-image"
              id="domain-image"
              hidden
              accept="image/png, image/gif, image/jpeg"
              onChange={handleImageChange}
            />
          </Button>

          {image && (
            <div className="image-preview">
              <Typography align="center" variant="caption">
                Prévisualisation :
              </Typography>
              <img
                src={imgUrls.preview}
                alt="Prévisualisation de la selection"
                style={{
                  border: `solid 1px ${theme.palette.primary.main}`,
                  borderRadius: theme.shape.borderRadius,
                }}
              />
            </div>
          )}

          <Box
            display="flex"
            gap="15px"
            alignItems="center"
            justifyContent="center"
          >
            <ButtonGroup>
              <Button color="error" onClick={props.close}>
                Annuler
              </Button>
              <Button
                color="success"
                onClick={() => {
                  addDomain(false);
                  props.close();
                }}
              >
                Valider
              </Button>
              <Button
                variant="contained"
                color="success"
                onClick={() => {
                  addDomain(true);
                  props.close();
                }}
              >
                Valider et publier
              </Button>
            </ButtonGroup>
          </Box>
        </div>
      </Container>
    </Dialog>
  );
}
