import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import React, { useState } from "react";
import { deleteDoc, doc } from "@firebase/firestore";
import { deleteObject, listAll, ref } from "@firebase/storage";
import { db, storage } from "../../database/firebase";
import { useSnackbar } from "notistack";

export default function DeleteProject({
  domainId,
  domainTitle,
  projectId,
  projectName,
}) {
  const { enqueueSnackbar } = useSnackbar();

  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClickClose = () => {
    setOpen(false);
  };

  const handleDeleteProject = () => {
    setOpen(false);

    //Suprression du doc firebase associé
    let docRef = doc(db, "content", domainId, "projects", projectId);
    deleteDoc(docRef)
      //Suppression des potentielles images associées
      .then(() => {
        let listRef = ref(
          storage,
          `images/domains/${domainTitle}/${projectName}`
        );
        listAll(listRef).then((res) => {
          res.items.forEach((itemRef) => {
            deleteObject(itemRef);
            console.log("someting was deleted")
          });
          res.prefixes.forEach((folderRef) => {
            listAll(folderRef).then((res) => {
              res.items.forEach((itemRef) => {
                deleteObject(itemRef);
              });
            });
          });
        });
      })
      .catch((e) => {
        enqueueSnackbar(`Erreur : ${e.message}`, { variant: "error" });
      })
      .then(() => {
        enqueueSnackbar(`Le projet "${projectName}" a bien été supprimé.`, {
          variant: "success",
        });
      });
  };

  return (
    <>
      <>
        <Tooltip title="Supprimer le projet">
          <IconButton color="error" onClick={handleClickOpen}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </>
      <Dialog
        open={open}
        onClose={handleClickClose}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-content"
      >
        <DialogTitle id="dialog-title">
          {`Supprimer le projet ${projectName}`}
        </DialogTitle>
        <DialogContent id="dialog-content">
          <Typography color="text.primary">{`Êtes vous sûr(e) de vouloir supprimer le projet "${projectName}" ?`}</Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="filled" onClick={handleClickClose}>
            Annuler
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={handleDeleteProject}
          >
            Confirmer
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
