
import { useTheme } from '@mui/material'
import React, { useState } from 'react'

import Letter from './Letter'

export default function Emphasis(props) {

    const theme = useTheme()

    const [hover, setHover] = useState(false)

    const handleHover = () => {
        setHover(!hover)
    }

    return (
        <strong
            onMouseEnter={handleHover}
            onMouseLeave={handleHover}
            style={{
                cursor: 'inherit',
                color: props.color? props.color : theme.palette.primary.main,
                fontWeight: 'bolder',

                whiteSpace: 'nowrap'
            }}
        >
            {props.children.split("").map((letter, i) => {

                return (
                    <Letter key={i}>{letter}</Letter>
                )
            })}
        </strong>
    )
}
