import React, { useState } from 'react'
import { Box, Button, TextField } from '@mui/material'
import { arrayUnion, doc, updateDoc } from 'firebase/firestore'
import { db } from '../../database/firebase'
import { useSnackbar } from 'notistack'

export default function Subscribe(props) {

    const valid_email = (email) => {
        return email.match(/^([\w\!\#$\%\&\'\*\+\-\/\=\?\^\`{\|\}\~]+\.)*[\w\!\#$\%\&\'\*\+\-\/\=\?\^\`{\|\}\~]+@((((([a-z0-9]{1}[a-z0-9\-]{0,62}[a-z0-9]{1})|[a-z])\.)+[a-z]{2,6})|(\d{1,3}\.){3}\d{1,3}(\:\d{1,5})?)$/i);
    }
    const valid_name = (name) => {
        return name.match(/^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u);
    }

    const [newUser, setNewUser] = useState({
        givenName: "",
        familyName: "",
        email: ""
    })

    const [regexErr, setRegexErr] = useState({
        givenName: false,
        familyName: false,
        email: false
    })

    const handleChangeGivenName = (e) => {
        setNewUser((prevState) => ({
            ...prevState,
            givenName: e.target.value
        }))
        setRegexErr((prevState) => ({
            ...prevState,
            givenName: valid_name(e.target.value) ? false : true,
        }))
    }
    const handleChangeFamilyName = (e) => {
        setNewUser((prevState) => ({
            ...prevState,
            familyName: e.target.value
        }))
        setRegexErr((prevState) => ({
            ...prevState,
            familyName: valid_name(e.target.value) ? false : true,
        }))
    }
    const handleChangeEmail = (e) => {
        setNewUser((prevState) => ({
            ...prevState,
            email: e.target.value
        }))
        setRegexErr((prevState) => ({
            ...prevState,
            email: valid_email(e.target.value) ? false : true,
        }))
    }

    const { enqueueSnackbar } = useSnackbar()

    const addSubscriber = (givenName, familyName, email) => {

        let docRef = doc(db, "newsletter", "LKVzB8zXo0WoR9GROd2S")

        let newUser = {
            givenName: givenName,
            familyName: familyName,
            email: email,
            timestamp: Date.now()
        }
        
        updateDoc(docRef, {
            subscribers : arrayUnion(newUser)
        })
        .then(() => {
            enqueueSnackbar('Inscription effectuée', {
                variant: "success"
            })
        })
    }

    const handleSubmit = () => {

        if (!regexErr.givenName && !regexErr.familyName && !regexErr.email && newUser.email !== "") {
            addSubscriber(newUser.givenName, newUser.familyName, newUser.email)
            setNewUser({
                givenName: "",
                familyName: "",
                email: ""
            })
        } else {
            enqueueSnackbar('Au moins un champ est mal renseigné, veuillez corriger pour pouvoir vous inscrire', {
                variant:"error"
            })
        }
    }

    return (
        <Box id="subscribe" align="center" display="flex" alignItems="center" justifyContent="center" gap="15px" sx={{whiteSpace: 'nozwrap', flexWrap: 'wrap'}}>
            <TextField 
                onChange={handleChangeGivenName}
                value={newUser.givenName}
                autoComplete="given-name"
                label="Prénom"
                size="small"
                sx={{width: '120px'}}
                error={regexErr.givenName}
            />
            <TextField
                onChange={handleChangeFamilyName}
                value={newUser.familyName}
                autoComplete="family-name"
                label="Nom"
                size="small"
                sx={{width: '170px'}}
                error={regexErr.familyName}
            />
            <TextField
                required
                onChange={handleChangeEmail}
                value={newUser.email}
                autoComplete="email"
                label="Adresse e-mail"
                size="small"
                sx={{width: '230px'}}
                error={regexErr.email}
            />
            <Button onClick={handleSubmit} variant="contained" >{props.mode === 'admin' ? "Ajouter" : "Abonnez-vous"}</Button>
        </Box>
    )
}
