import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check"
import { getStorage } from "firebase/storage"

import { getAuth } from "firebase/auth"

import { getAnalytics, logEvent } from "firebase/analytics";


export const firebaseConfig = {
    apiKey: "AIzaSyADhoDxwmlioViUCxziMRCFEx4GpfqMIlQ",
    authDomain: "proud-projects.firebaseapp.com",
    projectId: "proud-projects",
    storageBucket: "proud-projects.appspot.com",
    messagingSenderId: "300590245986",
    appId: "1:300590245986:web:d07a231e5492609338893b"
  };

// Initialize Firebase
export const app = initializeApp(firebaseConfig);


// Initialize Cloud Firestore and get a reference to the service
export const db = getFirestore(app);

//Initialize Cloud Storage
export const storage = getStorage(app)

// ! DEBUG TOKEN à enlever en prod
window.self.FIREBASE_APPCHECK_DEBUG_TOKEN = 'cf4888cf-3fb7-4b4e-8d31-a31201f2fced';
// cf4888cf-3fb7-4b4e-8d31-a31201f2fced


export const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider('6LfjN9UiAAAAAFJ9mKEu9zwOe6eT5F717tdoEO-N'),

  // Optional argument. If true, the SDK automatically refreshes App Check
  // tokens as needed.
  isTokenAutoRefreshEnabled: true,

});

export const auth = getAuth(app)

export const analytics = getAnalytics(app);

logEvent(analytics, 'notification_receveid');