import { Button, Typography } from '@mui/material'
import React, { useEffect } from 'react'

export default function Oups(props) {
    useEffect(() => {
        let oldTitle = document.title
        document.title = props.title
        return () => {
            document.title = oldTitle
        }
    }, [props.title])
    return (
        <>
            <Typography flex={1} variant="h4" color="text.primary" align="center">Oups ! On dirait que quelqu'un s'est perdu ? <br /> Cette page n'existe pas</Typography>
            <Button variant="contained" color="primary">Retour à l'accueil</Button>
        </>

    )
}
