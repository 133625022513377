import React from "react";
import "./SelectedProject.scss";
import FormatedText from "../FormatedText";
import CloseIcon from "@mui/icons-material/Close";
import { Chip, Fade, IconButton, Tooltip, useTheme } from "@mui/material";
import { useClickOutside } from "../../Hooks/useClickOutside";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import CelebrationIcon from '@mui/icons-material/Celebration';

export default function SelectedProject({ project, handleClose }) {
  const theme = useTheme();

  const [nbOfImages, setNbOfImages] = React.useState(0);

  function formatDate(date) {
    const options = { month: 'long', year: 'numeric' };
    const [year, month] = date.split('-');
    const formattedDate = new Date(year, month - 1).toLocaleDateString('fr-FR', options);
    return formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);
  }

  //reset states when project changes
  React.useEffect(() => {
    setNbOfImages(project?.data.gallery.length || 0);
    setCurrentImage(0);
    setFullscreen(false)
  }, [project]);

  const [currentImage, setCurrentImage] = React.useState(0);
  const [fullscreen, setFullscreen] = React.useState(false);

  //Block scroll when modal is open
  React.useEffect(() => {
    if (project) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [project]);

  //Handle currentImage when next or prev buttons are clicked
  const handleNextOrPrevImage = (mode) => {
    if (nbOfImages > 1) {
      if (mode === "next") {
        setCurrentImage((prev) => (prev === nbOfImages - 1 ? 0 : prev + 1));
      } else {
        setCurrentImage((prev) => (prev === 0 ? nbOfImages - 1 : prev - 1));
      }
    }
  };

  //Handle currentImage when arrow keys are pressed
  const bodyRef = React.useRef(document.body);
  React.useEffect(() => {
    const handleKeyDown = (e) => {
      if (nbOfImages > 1) {
        if (e.key === "ArrowRight") {
          handleNextOrPrevImage("next");
        } else if (e.key === "ArrowLeft") {
          handleNextOrPrevImage("prev");
        }
      }
    };
    bodyRef.current.addEventListener("keydown", handleKeyDown);
    return () => {
      bodyRef.current.removeEventListener("keydown", handleKeyDown);
    };
  }, [currentImage, handleNextOrPrevImage, nbOfImages]);

  const outsideClickRef = useClickOutside(handleClose);

  return (
    <Fade in={project ? true : false}>
      <div
        className={`selected-project  ${fullscreen ? 'fullscreen' : ''}`}
        style={{

        }}
        ref={outsideClickRef}
      >
        <div className="selected-project-inner-container">
          <div className="close-btn">
            <IconButton
              aria-label=""
              onClick={handleClose}
              sx={{
                border: "1px solid white",
              }}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <div className={`selected-project-img-container ${fullscreen ? 'fullscreen' : ''}`}>
            <img
              className="selected-project-img current"
              src={project?.data.gallery[currentImage]?.original}
              alt=""
            />
            <div className="prev-img-btn-container">
              {nbOfImages > 1 && (
                <IconButton
                  size="large"
                  style={{
                    backgroundColor: "rgba(0,0,0,0.8)",
                  }}
                  className="prev-img-btn"
                  onClick={() => handleNextOrPrevImage("prev")}
                >
                  <NavigateBeforeIcon />
                </IconButton>
              )}
            </div>
            <div className="next-img-btn-container">
              {nbOfImages > 1 && (
                <IconButton
                  size="large"
                  style={{
                    backgroundColor: "rgba(0,0,0,0.8)",
                  }}
                  className="next-img-btn"
                  onClick={() => handleNextOrPrevImage("next")}
                >
                  <NavigateNextIcon />
                </IconButton>
              )}
            </div>
            <div className="fullscreen-img-btn-container">
              <IconButton
                size="large"
                style={{
                  backgroundColor: "rgba(0,0,0,0.8)",
                }}
                className="next-img-btn"
                onClick={() => setFullscreen(prev => !prev)}
              >
                {fullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
              </IconButton>
            </div>
          </div>
          <div
            className={`selected-project-body ${fullscreen ? 'fullscreen' : ''}`}
            style={{
              padding: fullscreen ? 0 : theme.spacing(2),
            }}
          >
            <div
              className="selected-project-head"
              style={{
                gap: theme.spacing(1),
                marginBottom: theme.spacing(2),
              }}
            >
              <FormatedText
                text={project?.data.name || ""}
                variant="h4"
                fontWeight={300}
                align="center"
              />
              <FormatedText
                text={project?.data.subtitle || ""}
                variant="h5"
                fontWeight={300}
                align="center"
              />
              <div className="selected_project_first_infos" style={{gap: theme.spacing(1)}}>
                {project?.data.numberOfParticipants > 0 &&
                  <div className="selected-project-invited-number">
                    <Tooltip title="Participants" placement="top">
                      <Chip icon={<PeopleAltIcon />} label={project?.data.numberOfParticipants} color="primary" />
                    </Tooltip>
                  </div>
                }
                {project?.data.date &&
                  <div className="project-date">
                    <Chip icon={<CalendarMonthIcon />} label={formatDate(project?.data?.date)} color="primary" />
                  </div>
                }
                {project?.data.numberOfEvents &&
                  <div className="project-nb-of-events">
                    <Tooltip title="Nombre d'évènements" placement="top">
                      <Chip icon={<CelebrationIcon />} label={project?.data?.numberOfEvents} color="primary" />
                    </Tooltip>
                  </div>
                }
              </div>
            {project?.data.missions &&
              <div className="selected-project-missions" style={{gap: theme.spacing(1)}}>
                {project?.data.missions && project?.data.missions.split(',').map((item, index) => (
                  <Chip label={item} color="primary" />
                ))}
              </div>
            }
            </div>

            <FormatedText text={project?.data.description} align="left" />
          </div>
        </div>
      </div>
    </Fade>
  );
}
