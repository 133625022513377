import React from "react";
import { Outlet } from "react-router";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";

export default function Layout(props) {

  return (
    <>
      <Header />
      <main>
        <Outlet />
      </main>
      <Footer user={props.user} />
    </>
  );
}
