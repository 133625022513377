import { signOut, updatePassword } from "@firebase/auth";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { auth } from "../../../database/firebase";
import "./Profile.scss";

export default function Profile(props) {
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();

  //change password handling
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen((prev) => !prev);
  };

  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [validPassword, setValidPassword] = useState(false);
  const passwordRegex = new RegExp(
    /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,}$/
  );

  const passwordValidation = (password) => {
    setValidPassword(passwordRegex.test(password));
  };

  const handleNewPassword = (e) => {
    setNewPassword(e.target.value);
    passwordValidation(e.target.value);
  };
  const handleConfirmNewPassword = (e) => {
    setConfirmNewPassword(e.target.value);
  };

  const changePassword = () => {
    if (validPassword && newPassword === confirmNewPassword) {
      updatePassword(props.user, newPassword)
        .then(() => {
          console.log("mot de passe changé");
          setOpen(false);
        })
        .catch((e) => {
          console.log(e);
          signOut(auth).then(() => {
            enqueueSnackbar("Veuillez vous reconnecter pour modifier votre mot de passe", { variant: "error" });
          });
        });
    }
  };

  return (
    <div className="profile-container" style={{ gap: theme.spacing(2) }}>
      <Typography align="center" color="text.primary" variant="h5">
        Profil
      </Typography>
      <Typography align="center" color="text.primary">
        {props.userDatabase.email}
      </Typography>
      <Typography align="center" color="text.primary">
        Rôle : {props.userDatabase.role}
      </Typography>
      <Typography align="center">
        <Button variant="contained" onClick={handleOpen}>
          Changer de mot de passe
        </Button>
      </Typography>
      <Typography align="center">
        <Button
          variant="contained"
          onClick={() => {
            signOut(auth).then(() => {
              enqueueSnackbar("Déconnecté", { variant: "success" });
            });
          }}
        >
          Déconnexion
        </Button>
      </Typography>
      <Dialog
        open={open}
        onClose={handleOpen}
        maxWidth="xs"
        PaperProps={{ sx: { p: 1 } }}
      >
        <DialogTitle>Changer de mot de passe</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Si vous ne vous êtes pas connecté récemment, le système vous
            demandra vos identifiants.
          </DialogContentText>
          <TextField
            error={newPassword !== "" && !validPassword}
            autoFocus
            margin="dense"
            id="new-password"
            label="Nouveau mot de passe"
            type="password"
            fullWidth
            variant="standard"
            onChange={handleNewPassword}
            helperText={
              validPassword
                ? ""
                : "Merci de renseigner un mot de passe contenant au moins 8 lettres, une majuscule, une minuscule et un caractère spécial (@$!%*#?&). "
            }
          />
          <TextField
            error={
              confirmNewPassword !== "" && confirmNewPassword !== newPassword
            }
            margin="dense"
            id="confirm-new-password"
            label="Confirmer le nouveau mot de passe"
            type="password"
            fullWidth
            variant="standard"
            onChange={handleConfirmNewPassword}
            helperText={
              confirmNewPassword !== "" && confirmNewPassword !== newPassword
                ? "Veuillez renseigner le même mot de passe."
                : ""
            }
          />
        </DialogContent>
        <DialogActions>
          <Button color="error" onClick={handleOpen}>
            Annuler
          </Button>
          <Button variant="contained" onClick={changePassword}>
            Changer le mot de passe
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
