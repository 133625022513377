import {
  Box,
  Button,
  ButtonGroup,
  Container,
  Dialog,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useRef, useState } from "react";
import {
  addDoc,
  collection,
  getDocs,
  limit,
  orderBy,
  query,
} from "firebase/firestore";
import { db, storage } from "../../database/firebase";
import "./CreateProjectDialog.scss";
import { getDownloadURL, ref, uploadBytes } from "@firebase/storage";
import { v4 as uuidv4 } from 'uuid'

export default function CreateProjectDialog(props) {
  const theme = useTheme();

  const [gallery, setGallery] = useState([]);
  const [galleryBlobs, setGalleryBlobs] = useState([]);

  // Input Refs

  const nameRef = useRef(null);
  const descRef = useRef(null);
  const numberOfParticipantsRef = useRef(null);
  const dateRef = useRef(null);

  const handleGalleryChange = (event) => {
    let files = Array.from(event.target.files);
    console.log(files);
    files.forEach((galleryImage) => {
      setGallery((prev) => [...prev, galleryImage]);
      let url = URL.createObjectURL(galleryImage);
      setGalleryBlobs((prev) => [...prev, url]);
    });
  };

  const addProjectinDomain = (domainId, name, numberOfParticipants, date, description, gallery) => {
    let domainProjects = collection(db, "content", domainId, "projects")

    //get existings docs max order
    const q = query(
      domainProjects,
      orderBy("order", "desc"),
      limit(1)
    );

    getDocs(q).then((documents) => {
      let maxIndex = 0;
      documents.forEach((doc) => {
        maxIndex = doc.data().order;
      });

      let array = {
        name: name,
        numberOfParticipants: numberOfParticipants,
        date: date,
        description: description,
        gallery: [],
        order: (maxIndex + 1),
      }
      if (gallery[0]) {
  
        let galleryArray = []
        let imageProcessed = 0
        gallery.forEach((img, index, ar) => {
          let imageUuid = img.name + uuidv4()
          let imageRef = ref(
            storage,
            `images/domains/${props.domainTitle}/${nameRef.current?.value}/gallery/${imageUuid}`
          );
          uploadBytes(imageRef, img).then(() => {
            getDownloadURL(imageRef).then((url) => {
              galleryArray[index] = {
                id: imageUuid,
                original: url,
                thumbnail: url,
                originalTitle: img.name,
              };
              imageProcessed++;
              if (imageProcessed === ar.length) {
                array.gallery = galleryArray
                //Add project doc
                addDoc(domainProjects, array)
                //Reset input files
                setGallery([])
              }
            });
          });
        });
        
      } else {
        //Add project doc
        addDoc(domainProjects, array)
        //Reset input files
        setGallery([])
      }

    })

  };

  const handleSubmit = (published) => {
    addProjectinDomain(props.domainId, nameRef.current?.value,numberOfParticipantsRef.current?.value, dateRef.current?.value, descRef.current?.value, gallery, published)
  }


  return (
    <Dialog open={props.open} onClose={props.close}>
      <Container width="100%" maxWidth="xl">
        <div
          className="create-project"
          style={{
            padding: theme.spacing(4),
          }}
        >
          <Typography color="text.primary" variant="h5" mb={2}>
            Ajouter un projet {props.domainTitle.toLowerCase()}
          </Typography>
          <TextField label="Nom du projet" inputRef={nameRef} />

          <TextField label="Nombre de participant" type="number" inputRef={numberOfParticipantsRef}/>
          <TextField label="Date" type="month" inputRef={dateRef}/>
          <TextField label="Description" inputRef={descRef} multiline />

          <Button component="label" variant="contained">
            Ajouter une/des photo(s) à la gallerie
            <input
              type="file"
              name="project-gallery"
              id="project-gallery"
              hidden
              multiple
              accept="image/png, image/gif, image/jpeg"
              onChange={handleGalleryChange}
            />
          </Button>

          {gallery[0] && (
            <div className="gallery-preview">
              <Typography align="center" variant="caption">
                Prévisualisation de la selection:
              </Typography>
              <div className="previews-container">
                {galleryBlobs.map((src, index) => {
                  return (
                    <img
                      key={index}
                      src={src}
                      alt="Prévisualisation de la selection"
                      style={{
                        border: `solid 1px ${theme.palette.primary.main}`,
                        borderRadius: theme.shape.borderRadius,
                      }}
                    />
                  );
                })}
              </div>
            </div>
          )}

          <Box
            display="flex"
            gap="15px"
            alignItems="center"
            justifyContent="center"
          >
            <ButtonGroup>
              <Button color="error" onClick={props.close}>
                Annuler
              </Button>
              <Button
                color="success"
                onClick={() => {
                  handleSubmit(false);
                  props.close();
                }}
              >
                Valider
              </Button>
              <Button
                variant="contained"
                color="success"
                onClick={() => {
                  handleSubmit(true);
                  props.close();
                }}
              >
                Valider et publier
              </Button>
            </ButtonGroup>
          </Box>
        </div>
      </Container>
    </Dialog>
  );
}
