import { Typography, useTheme } from "@mui/material";
import React from "react";
import { NavLink } from "react-router-dom";

export default function ProudLink(props) {

    const theme = useTheme()

    let notActiveStyle = {
        color: theme.palette.primary.main
    }

    let activeStyle = {
        color: theme.palette.secondary.main
    }


  return (
    <Typography variant="h4" as="li">
      <NavLink to={props.path} className="fullscreen-link" style={({ isActive }) => isActive ? activeStyle: notActiveStyle}>
        {props.label}
      </NavLink>
      <div
        className="before-link"
        style={{
          background: theme.palette.secondary.main,
        }}
      />
    </Typography>
  );
}
