import { useState, useEffect } from "react";
import { unparse } from "papaparse";
import { db } from "../database/firebase";
import { doc, onSnapshot, query } from "@firebase/firestore";

export function useDataAndDownloadCsv() {
  const [data, setData] = useState([]);

  useEffect(() => {
    const q = query(doc(db, "newsletter", "LKVzB8zXo0WoR9GROd2S"));
    const unsubscribe = onSnapshot(q, (doc) => {
      setData(doc.data().subscribers);
    });

    return () => unsubscribe();
  }, []);

  const downloadCsv = () => {
    const csv = unparse(data);
    const blob = new Blob([csv], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.setAttribute("hidden", "");
    a.setAttribute("href", url);
    a.setAttribute("download", "data.csv");
    document.body.appendChild(a);
    a.click();
    a.remove();
  };

  return { data, downloadCsv };
}
